import React, { useState, useContext } from "react";
import { Chart } from "chart.js";
import {
  Box,
  Typography,
  Container,
  Grid,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { tabDesign1 } from "../../common/componentStyle";
import { TabPanel, a11yProps } from "../../common/mUiComponents";
import Financial from "../results/Financial";
import Emissions from "../results/Emisssions";
import Cii from "../results/Cii";
import { chartContext } from "../../common/ChartContex";
import Loading from "../../component/Loading";
import EUETSCostsChart from "../results/EUETSCostsChart";
import { formatNumber } from "../../common/formatter";
import { Tooltip } from "chart.js";

const IndexResult = ({ loading }) => {
  const theme = useTheme();

  const tabs = [
    { id: 1, label: "Financial" },
    { id: 2, label: "Emissions" },
    { id: 3, label: "CII" },
    { id: 4, label: "Cost of Compliance" },
  ];

  const [show, setShow] = useState(true);
  const chartData = useContext(chartContext);

  const [currentTab, setCurrentTab] = useState(0);
  const changeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const tabOrientation = isMobile ? "horizontal" : "vertical";
  const variant = isMobile ? "scrollable" : "fullWidth";

  Tooltip.positioners.myCustomPositioner = function(elements, eventPosition) {
    const tooltip = this;

    // Calculate the tooltip position based on mouse event position
    const x = eventPosition.x;
    const y = eventPosition.y;

    // Return the position where the tooltip should be drawn
    return {
        x: x,
        y: y,
        // You may also include xAlign and yAlign to override those tooltip options.
        xAlign: 'center',
        yAlign: 'bottom'
    };
};
  // options

  const compliance_myEUETSCostsOptions = {
    responsive: true,
    layout: {
      padding: {
        top: 70
      }
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        position: "myCustomPositioner",
        callbacks: {
          label: function (context) {
            if (typeof context.formattedValue === "string") {
              let formattedValue = context.formattedValue;

              let newData = parseFloat(formattedValue).toFixed(2);

              // let label = formattedValue.split(".")[0].replace(/,/g, "");

              let formattedLabel = newData.toString();

              return context.dataset.label + " : $" + formattedLabel + " MUSD";
            } else {
              return context.formattedValue;
            }
          },
        },
      },
      // legend: false,
      legend: {
        position: "bottom",
        boxBorderStyle: "none",
        labels: {
          padding: 30,
          color: "#000",

          font: {
            size: 11,
            lineHeight: 14,
          },
          generateLabels: (chart) => {
            const originalLabels =
              Chart.defaults.plugins.legend.labels.generateLabels(chart);
            originalLabels.forEach((label) => {
              label.lineWidth = 0; // Set the line width to 0 to remove the border
            });
            return originalLabels;
          },
        },
      },
    },
    tension: 0.1,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "#8893a4",
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
          callback: function (value, index, ticks) {
            if (value === 0) {
              return "$";
            } else {
              return "$" + value.toFixed(1).toLocaleString();
            }
          },
        },
        title: {
          display: true,
          text: chartData?.myEUETSCosts?.y_axisTitle,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
        },
        title: {
          display: true,
          text: chartData?.myEUETSCosts?.x_axisTitle,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
    },
  };

  const yearlyFuelExpenditureOptions = {
    responsive: true,
    layout: {
      padding: {
        top: 50
      }
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        position: "myCustomPositioner",
        callbacks: {
          label: function (context) {
            if (typeof context.formattedValue === "string") {
              let formattedValue = context.formattedValue;

              let newData = parseFloat(formattedValue).toFixed(2);

              // let label = formattedValue.split(".")[0].replace(/,/g, "");

              let formattedLabel = newData.toString();

              return "$ " + formattedLabel + " MUSD";
            } else {
              return context.formattedValue;
            }
          },
        },
      },
      legend: {
        position: "bottom",
        boxBorderStyle: "none",
        labels: {
          padding: 30,
          color: "#000",

          font: {
            size: 11,
            lineHeight: 14,
          },
          generateLabels: (chart) => {
            const originalLabels =
              Chart.defaults.plugins.legend.labels.generateLabels(chart);
            originalLabels.forEach((label) => {
              label.lineWidth = 0; // Set the line width to 0 to remove the border
            });
            return originalLabels;
          },
        },
      },
    },
    tension: 0.1,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "#374154", // Customize Y-axis grid color here
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
          callback: function (value, index, ticks) {
            // if (value === 0) {
            //   return "$";
            // } else {
            // return "$" + value.toLocaleString();
            // }
            return "$" + value.toFixed(1).toLocaleString();
          },
        },
        title: {
          display: true,
          text: chartData?.cashFlowChartSpecification?.y_axisTitle,
          color: "#9E9E9E",
          lineHeight: "10px",
          padding: { bottom: 17 },
        },
      },
      x: {
        grid: {
          color: "#8893a4", // Customize Y-axis grid color here
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
        },
        title: {
          display: true,
          text: chartData?.cashFlowChartSpecification?.x_axisTitle,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
    },
  };
  const fuelExpenseOption = {
    responsive: true,
    maintainAspectRatio: true,
    barPercentage: 0.4,
    layout: {
      padding: {
        top: 50
      }
    },
    scaleLineColor: "transparent",
    plugins: {
      tooltip: {
        position: "myCustomPositioner",
        callbacks: {
          label: function (context) {
            if (typeof context.formattedValue === "string") {
              let formattedValue = context.formattedValue;

              let newData = parseFloat(formattedValue).toFixed(2);

              // let label = formattedValue.split(".")[0].replace(/,/g, "");

              let formattedLabel = newData.toString();

              return "$ " + formattedLabel + " MUSD";
            } else {
              return context.formattedValue;
            }
          },
        },
      },
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
        },
        align: "center",
        anchor: "center",
        color: "#FFFFFF",
        font: {
          size: 12,
          weight: 500,
          lineHeight: 15,
        },
        formatter: function (value, context) {
          return (
            context.dataset.label +
            ": " +
            value.toLocaleString(undefined, { maximumFractionDigits: 2 })
          );
        },
      },
      legend: {
        display:true,
        position: "bottom",
        boxBorderStyle: "none",
        labels: {
          padding: 30,
          color: "#000",
          font: {
            size: 11,
            lineHeight: 14,
          },
         generateLabels: (chart) => {
            const originalLabels =
              Chart.defaults.plugins.legend.labels.generateLabels(chart);

            if (originalLabels) {
              return originalLabels;
            }
            originalLabels.forEach((label) => {
              label.lineWidth = 0; // Set the line width to 0 to remove the border
            });
            return originalLabels;
          },
        },
      },
    },
    scales: {
      x: {
        color: "#3c4450",
        font: {
          size: 10,
          lineHeight: 13,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
        },
        stacked: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: chartData?.fuelExpense?.x_axisTitle,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
      y: {
        grid: {
          color: "#8893a4", // Customize Y-axis grid color here
        },
        color: "#FFFFFF",
        font: {
          size: 10,
          lineHeight: 13,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
          callback: function (value, index, ticks) {
            return "$" + value.toFixed(1).toLocaleString();
          },
        },
        stacked: true,
        border: {
          display: false,
        },
        beginAtZero: true,
        title: {
          display: true,
          text: chartData?.fuelExpense?.y_axisTitle,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
    },
  };

  const emissionsAttributableToCombustionOptions = {
    responsive: true,
    layout: {
      padding: {
        top: 70
      }
    },
    maintainAspectRatio: false,
    barPercentage: 0.4,
    scaleLineColor: "transparent",
    plugins: {
      tooltip: {
        position: "myCustomPositioner",
        callbacks: {
          label: function (context) {
            if (typeof context.formattedValue === "string") {
              let label = context.dataset.label;

              let gwp = context.dataset.gwp[context.dataIndex];
              let qty = context.dataset.qty[context.dataIndex];

              let gwpLabel = ` GWP: ${gwp} mtCO2eq`;
              let qtyLabel = ` QTY: ${qty} mt ${label}`;
              
              return [gwpLabel, qtyLabel];
            } else {
              return context.formattedValue;
            }
          },
        },
      },
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 0; // or >= 1 or ...
        },
        align: "center",
        anchor: "center",
        color: "#FFF",
        font: {
          size: 12,
          weight: 500,
          lineHeight: 15,
        },

        formatter: function (value, context) {
          if (context.dataIndex === 0) {
            return (
              context.dataset.label + " : " + context.dataset.qty[0] + " mt"
            );
          } else if (context.dataIndex === 1) {
            return (
              context.dataset.label + " : " + context.dataset.qty[1] + " mt"
            );
          } else if (context.dataIndex === 2) {
            return (
              context.dataset.label + " : " + context.dataset.qty[2] + " mt"
            );
          } else if (context.dataIndex === 3) {
            return (
              context.dataset.label + " : " + context.dataset.qty[3] + " mt"
            );
          }
          return context.dataset.label + ": " + value.toLocaleString();
        },
      },
      legend: {
        position: "bottom",
        boxBorderStyle: "none",
        labels: {
          padding: 30,
          color: "#000",
          font: {
            size: 11,
            lineHeight: 14,
          },
          generateLabels: (chart) => {
            const originalLabels =
              Chart.defaults.plugins.legend.labels.generateLabels(chart);

            if (originalLabels) {
              return originalLabels;
            }
            originalLabels.forEach((label) => {
              label.lineWidth = 0; // Set the line width to 0 to remove the border
            });
            return originalLabels;
          },
        },
      },
    },
    scales: {
      x: {
        color: "#FFFFFF",
        font: {
          size: 10,
          lineHeight: 13,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
        },
        stacked: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: chartData?.totalPollutionChartSpecification_gwp20?.x_axisTitle,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
      y: {
        grid: {
          color: "#8893a4", // Customize Y-axis grid color here
        },
        color: "#FFFFFF",
        font: {
          size: 10,
          lineHeight: 13,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
        },
        stacked: true,
        border: {
          display: false,
        },
        beginAtZero: true,
        title: {
          display: true,
          text: chartData?.totalPollutionChartSpecification_gwp20?.y_axisTitle,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
    },
  };

  const ciiOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        color: "#ffffff",
        font: {
          size: 10,
          lineHeight: 20,
          weight: 500,
        },
        formatter: function (value, context) {
          var label = context.dataset.type === "scatter" ? value.label : "";
          return label;
        },
      },

      tooltip: {
        position: "myCustomPositioner",
        filter: function (tooltipItem) {
          if (tooltipItem.datasetIndex > 0) {
            return tooltipItem;
          }
        },
        callbacks: {
          label: function (context) {
            if (typeof context.formattedValue === "string") {
              let formattedValue = context.formattedValue;

              let newData = parseFloat(formattedValue).toFixed(2);
              
              let formattedLabel = newData.toString();

              return context.dataset.label + " : " + formattedLabel +" " + "grCO2/ton-nm";
            } else {
              return context.formattedValue;
            }
          },
        },
      },

      legend: {
        position: "bottom",
        boxBorderStyle: "none",
        labels: {
          padding: 30,
          color: "#000",
          font: {
            size: 11,
            lineHeight: 14,
          },
          generateLabels: (chart) => {
            const originalLabels =
              Chart.defaults.plugins.legend.labels.generateLabels(chart);

            let labels = [];
            if (originalLabels.length > 0) {
              originalLabels.map((label) => {
                if (typeof label.text === "string" && label.text.length > 2) {
                  labels.push(label);
                }
              });
            }
            return labels;
          },
        },
      },
    },
    scales: {
      x: {
        color: "#FFFFFF",
        font: {
          size: 10,
          lineHeight: 13,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
        },
        stacked: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: chartData?.ciiChartSpecification?.x_axisTitle,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
      y: {
        grid: {
          color: "#374154", // Customize Y-axis grid color here
        },
        color: "#FFFFFF",
        font: {
          size: 10,
          lineHeight: 13,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
        },
        stacked: false,
        border: {
          display: false,
        },
        beginAtZero: true,
        title: {
          display: true,
          text: chartData?.ciiChartSpecification?.y_axisTitle,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
    },
  };

  const eu_chartOption = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        position: "myCustomPositioner",
        callbacks: {
          label: function (context) {
            if (typeof context.formattedValue === "string") {
              let formattedValue = context.formattedValue;

              let newData = parseFloat(formattedValue).toFixed(2);

              // let label = formattedValue.split(".")[0].replace(/,/g, "");

              let formattedLabel = newData.toString();

              return context.dataset.label + " : $" + formattedLabel +" " + "MUSD";
            } else {
              return context.formattedValue;
            }
          },
        },
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    tension: 0.1,
    scales: {
      x: {
        color: "#FFFFFF",
        font: {
          size: 10,
          lineHeight: 13,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
        },
        stacked: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: chartData?.myCOCchart?.x_axisTitle,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
      y: {
        suggestedMin: 0,
        grid: {
          color: "#374154", // Customize Y-axis grid color here
        },
        color: "#FFFFFF",
        font: {
          size: 10,
          lineHeight: 13,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
          callback: function (value, index, ticks) {
            if (value === 0) {
              return "$";
            } else {
              return "$" + value.toFixed(1).toLocaleString();
            }
          },
        },
        stacked: true,
        border: {
          display: false,
        },
        beginAtZero: true,
        title: {
          display: true,
          text: chartData?.myCOCchart?.y_axisTitle,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
    },
  };

  const customTabLabel = (tab) => {
    return (
      <Box className="flex items-center">
        <Typography
          variant="h6"
          className={
            currentTab === tab.id - 1
              ? "capitalize text-white text-base font-black"
              : "capitalize text-updated-primary-text text-base font-black"
          }
        >
          {tab.label}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
      }}
    >
      <Container maxWidth="xl" className="flex flex-col items-center relative">
        {!loading && chartData ? (
          <Grid container spacing={2} className="pt-[20px] result-container">
            <Grid item lg={2} md={2} xs={12}>
              <Tabs
                orientation={tabOrientation}
                value={currentTab}
                onChange={changeTab}
                aria-label="basic tabs example"
                variant={variant}
                className="result-tabs"
                scrollButtons
                allowScrollButtonsMobile
                sx={tabDesign1}
              >
                {tabs.map((tab) => (
                  <Tab
                    label={customTabLabel(tab)}
                    {...a11yProps(tab.id)}
                    className={
                      currentTab === tab.id - 1 ? "tab-btn-gradient" : "tab-btn"
                    }
                    key={tab.id}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid item lg={10} md={10} xs={12}>
              <>
                <TabPanel value={currentTab} index={0}>
                  <Box className="financial-conatiner overflow-y-auto">
                    <Financial
                      optionsCCO={yearlyFuelExpenditureOptions}
                      fuelExpenseOptions={fuelExpenseOption}
                      show={show}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                  <Box className="emmission-container overflow-y-auto">
                    <Emissions
                      optionsEmmission={
                        emissionsAttributableToCombustionOptions
                      }
                      optionsEmmission_gwp={
                        emissionsAttributableToCombustionOptions
                      }
                      show={show}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                  <Box className="comliance-container rounded-2xl bg-new-text overflow-y-auto">
                    <Cii optionsCII={ciiOptions} show={show} />
                  </Box>
                </TabPanel>

                <TabPanel value={currentTab} index={3}>
                  <Box className="euets-container overflow-y-auto">
                    <EUETSCostsChart
                      compliance_myEUETSCostsOptions={
                        compliance_myEUETSCostsOptions
                      }
                      eu_chartOption={eu_chartOption}
                      show={show}
                      textColor="text-orochimaru"
                    />
                  </Box>
                </TabPanel>
              </>
            </Grid>
          </Grid>
        ) : (
          <Box className="mt-[410px] loadeder-animation">
            <Loading visible={true} />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default IndexResult;
