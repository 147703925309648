export function addZeroes(num) {
  return num.toLocaleString("en", {
    useGrouping: false,
    minimumFractionDigits: 1,
  });
}

export const formateNumber = (number) => {
  return new Intl.NumberFormat("en-IN").format(number);
};

export const formateCurrency = (number) => {
  return formateNumber(number);
};

export function formatNumber(num) {
  if (!num) return 0;
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function createArrayBetween(start, end) {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
}

export const patternsObj = {
  diagonal45: "diagonal",
  diagonal: "diagonal",
  ring: "ring",
  plus: "plus",
  cross: "cross",
  dash: "dash",
  crosDash: "cross-dash",
  dotDash: "dot-dash",
  disc: "disc",
  weave: "weave",
  zigzag: "zigzag",
  zigzagVertical: "zigzag-vertical",
  diagonalRight: "diagonal-right-left",
  square: "square",
  box: "box",
  triangle: "triangle",
  triangleInverted: "triangle-inverted",
  dimond: "diamond",
  dimondBox: "diamond-box",
  empty: "dot",
  verticalstripe: "line-vertical",
  horizontalstripe: "line",
  dots: "dot",
  dot: "dot",
};


export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { month: "short", day: "2-digit", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
}
