import React, { useRef } from "react";
import { TabPanel } from "../common/mUiComponents";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatNumber, formateCurrency } from "../common/formatter";
import InfoImg from "../assets/images/square-images/info.svg";

const vesselsDefaultImage  =  "https://storage.googleapis.com/vessel-images/default_vessel_image.jpg";
const SelectVessel = ({
  currentTab,
  changeSelectedVessel,
  changeVesselImg,
  vesselTypesImages,
  selectVessel,
  vesselTypes,
  handleVesselInfoModal,
  isLoadingVesselData,
}) => {
  const example5sbRef = useRef(null);
  return (
    <TabPanel value={currentTab} index={0} className="mt-[88px] mb-[50px] p-2">
      <Grid container className="xl:pe-3">
        <Grid item xs={12} md={12} lg={7} className="flex justify-center">
          <Box className="xl:w-[622px] xl:h-[512px] sm:w-auto sm:h-auto pb-3">
            <img src={changeVesselImg} alt="vessel" 
                 onError={(e) => { e.target.src = vesselsDefaultImage; }}
            className="w-full h-full" />
          </Box>
        </Grid>
        <div className="examples">
          <Grid
            item
            lg={5}
            md={12}
            xs={12}
            id="example5"
            style={{ zIndex: 1 }}
            className="containers off-bottom show-icon py-6 pl-6 pr-1 rounded-2xl bg-list-bg"
          >
            <Box
              id="style-4"
              className="h-full overflow-auto mr-1"
              ref={example5sbRef}
            >
              {vesselTypes?.map((i) => {
                if (i.visibleInFE === true) {
                  return (
                    <React.Fragment key={i.id}>
                      <Card
                        style={{ zIndex: 1 }}
                        sx={{ backgroundColor: "transparent" }}
                        className={
                          i === selectVessel
                            ? "mb-[10px] mr-4 selected-bg-gradient  relative rounded-[7px] cursor-pointer"
                            : "mb-[10px] mr-4 border border-solid border-updated-primary-text shadow-none rounded-[7px] cursor-pointer"
                        }
                        onClick={() => {
                          changeSelectedVessel(i);
                        }}
                      >
                        <CardContent className="flex">
                          <Grid container spacing={1} className="relative">
                            <Grid item xs={12} sm={4} md={4} xl={4} lg={4}>
                              <Box className="h-[127px] lg:w-[127px] md:w-[60%] vessel-img-list">
                                {isLoadingVesselData ? (
                                  <Skeleton
                                    animation="wave"
                                    key={vesselTypes}
                                    width="100%"
                                    variant="rectangular"
                                    height={125}
                                  />
                                ) : (
                                    <img
                                      src={`https://storage.googleapis.com/vessel-images/vessel-image-${i.id}-thumbnail.png`}
                                      alt="vessel"
                                      onError={(e) => { e.target.src = vesselsDefaultImage; }}
                                      className="h-full w-full object-fill rounded-[7px]"
                                    />
                                  )
                                  
                                }
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={7} md={7} xl={7} lg={7}>
                              {isLoadingVesselData ? (
                                <Skeleton
                                  animation="wave"
                                  key={vesselTypes}
                                  width="100%"
                                  variant="rectangular"
                                  height={125}
                                  />
                              ) : (
                                <div className="ms-2">
                                  <Typography
                                    variant="h5"
                                    className="mb-1 text-updated-primary-text font-semibold text-xl leading-5 w-fit"
                                  >
                                    {i?.vesselTypeName}
                                  </Typography>
                                  <Grid container className="mb-1">
                                    <Grid item xs={4.5}>
                                      <Typography className="capitalize text-title text-xs font-black leading-[15px]">
                                        Capacity
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography className="lowercase text-title text-xs font-black leading-[15px]">
                                        : {formatNumber(i.capacity)}{" "}
                                        {i?.family?.vesselUnits?.suname}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="mb-1">
                                    <Grid item xs={4.5}>
                                      <Typography className="capitalize text-title text-xs font-black leading-[15px]">
                                        ME power
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography className="lowercase text-title text-xs font-black leading-[15px]">
                                        :{" "}
                                        {`${formateCurrency(
                                          i?.installedMEpower
                                        )} kw`}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="mb-1">
                                    <Grid item xs={4.5}>
                                      <Typography className="capitalize text-title text-xs font-black leading-[15px]">
                                        AUX power
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography className="lowercase text-title text-xs font-black leading-[15px]">
                                        :{" "}
                                        {formateCurrency(i?.installedAUXpower)}{" "}
                                        kw
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  {i.installedBoilerPower > 0 && (
                                    <Grid container className="mb-1">
                                      <Grid item xs={4.5}>
                                        <Typography className="capitalize text-title text-xs font-black leading-[15px]">
                                          Boiler power
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Typography className="lowercase text-title text-xs font-black leading-[15px]">
                                          : {i?.installedBoilerPower} kw
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                  <Grid container className="mb-1">
                                    <Grid item xs={4.5}>
                                      <Typography className="capitalize text-title text-xs font-black leading-[15px]">
                                        Min Speed
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography className="lowercase text-title text-xs font-black leading-[15px]">
                                        :{" "}
                                        {`${parseFloat(i?.speedMin).toFixed(
                                          1
                                        )} kt`}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="mb-1">
                                    <Grid item xs={4.5}>
                                      <Typography className="capitalize text-title text-xs font-black leading-[15px]">
                                        Max Speed
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography className="lowercase text-title text-xs font-black leading-[15px]">
                                        :{" "}
                                        {`${parseFloat(i?.speedMax).toFixed(
                                          1
                                        )} kt`}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </div>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={1} md={1} xl={1} lg={1}>
                              <div className="info-icon">
                                <Box>
                                  <Tooltip title="Notes and Sources" arrow>
                                    <Button
                                      className={
                                        "!px-[12px] !py-[12px] module-border-wrap text-base leading-5 text-spinel-stone-black1 font-medium"
                                      }
                                      style={{
                                        height: "2em",
                                        width: "2em",
                                      }}
                                      type="submit"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleVesselInfoModal(
                                          i,
                                          vesselTypesImages[i.id]
                                        );
                                      }}
                                    >
                                      <img
                                        src={InfoImg}
                                        alt="Information"
                                        style={{ maxWidth: "none" }}
                                      />
                                    </Button>
                                  </Tooltip>
                                </Box>
                              </div>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </React.Fragment>
                  );
                }
              })}
            </Box>
          </Grid>
        </div>
      </Grid>
    </TabPanel>
  );
};

export default SelectVessel;
