import { Box, Grid, Skeleton, Slider, Typography } from "@mui/material";
import React, { useRef } from "react";
import GoogleMapReact from "google-map-react";

import { TabPanel } from "../common/mUiComponents";
import Loading from "./Loading";
import { CustomSliderStyles, mapOptions } from "../common/componentStyle";
import { formateCurrency } from "../common/formatter";
import { reactAppConfig } from "../config/react-app-config";

const AssignRoute = ({
  currentTab,
  setMap,
  routes,
  selectRoute,
  handleRoute,
  routeData,
  selectedRouteId,
  isFetchingKpiData,
  changeSpeed,
  vesselData,
  speedmin,
  selectSpeed,
  speedmax,
  setMapLoaded,
  mapLoaded,
}) => {
  const defaultProps = {
    center: {
      lat: 61.344078,
      lng: -17.578125,
    },
    zoom: 0,
  };

  const mapRefs = useRef(null);

  const handleMapLoaded = ({ map, maps }) => {
    setMap(map);
    if(selectRoute){
      setMapLoaded(false);
      handleRoute(selectRoute)
    }else{
      setMapLoaded(true);
    }
  };

  return (
    <TabPanel
      value={currentTab}
      index={1}
      className="xl:mt-[115px] xs:mt-[70px]  md:mt-[70px] mb-[50px] padding-css"
    >
      <Grid container spacing={4} className="mb-9 w-[100%] route-container">
        <Grid
          item
          xs={12}
          md={12}
          lg={6.5}
          className="md:mb-5 mb-5 xl:mb-0 h-[522px] w-full py-0 px-[1.5em] xs:w-auto"
        >
          <div className="bg-list-bg w-full h-full rounded-2xl overflow-hidden">
            {!mapLoaded && (
              <div className="loader-animation">
                <Loading visible={!mapLoaded} />
              </div>
            )}
            <div className="map-css w-full h-full">
              <GoogleMapReact
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals={true}
                bootstrapURLKeys={{
                  key: reactAppConfig.googleMapUrl,
                }}
                onGoogleApiLoaded={handleMapLoaded}
                options={mapOptions}
              ></GoogleMapReact>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={5.5} className="pt-0 px-0">
          <Box className="h-[455px] pl-6 py-6 pr-1 rounded-2xl bg-list-bg">
            <Grid
              container
              spacing={2}
              className="h-full overflow-auto pr-4 mt-1  items-stretch"
            >
              {routes?.map((r) => {
                if (r.visibleInFE === true) {
                  return (
                    <Grid
                      key={`select-route-&-speed-${r.routeId}`}
                      item
                      md={12}
                      xs={12}
                      lg={6}
                      className="pt-0 mb-4"
                    >
                      <Box
                        className={
                          r === selectRoute
                            ? "relative selected-bg-gradient h-full rounded-[7px] p-[10px] cursor-pointer"
                            : "h-full rounded-[7px] border border-solid shadow-none border-updated-primary-text p-[10px] cursor-pointer"
                        }
                        onClick={() => {
                          handleRoute(r);
                        }}
                      >
                        {r?.routeDataKPI ? (
                          <>
                            <Typography
                              variant="h5"
                              className="mb-1 text-updated-primary-text font-black text-base leading-5 w-fit"
                            >
                              {r?.routeName}
                            </Typography>

                            <>
                              <Grid
                                container
                                className="mb-1 align-items-center"
                              >
                                <Grid item xl={4} xs={4} lg={4} md={2}>
                                  <Typography className="text-title text-xs font-black leading-[15px]">
                                    Days Total
                                  </Typography>
                                </Grid>
                                <Grid item xl={8} xs={8} lg={8} md={5}>
                                  {isFetchingKpiData &&
                                  selectedRouteId === r.routeId ? (
                                    <Skeleton
                                      animation="wave"
                                      key={routeData}
                                      width="100%"
                                      height={15}
                                    />
                                  ) : (
                                    <Typography className="uppercase text-title text-xs font-medium leading-[15px]">
                                      :{" "}
                                      {r?.routeId === selectRoute?.routeId
                                        ? `${routeData?.daysTotal.toFixed(2)}`
                                        : r?.routeDataKPI?.daysTotal?.toFixed(
                                            2
                                          )}
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>

                              <Grid container className="mb-1">
                                <Grid item xs={4} xl={4} lg={4} md={2}>
                                  <Typography className="text-title text-xs font-black leading-[15px]">
                                    Yearly R/t
                                  </Typography>
                                </Grid>
                                <Grid item xs={8} xl={8} lg={8} md={5}>
                                  {isFetchingKpiData &&
                                  selectedRouteId === r.routeId ? (
                                    <Skeleton
                                      animation="wave"
                                      key={routeData}
                                      width="100%"
                                      height={15}
                                    />
                                  ) : (
                                    <Typography className="uppercase text-title text-xs font-medium leading-[15px]">
                                      :{" "}
                                      {r?.routeId === selectRoute?.routeId
                                        ? `${routeData?.yearlyRt.toFixed(2)}`
                                        : `${r?.routeDataKPI?.yearlyRt?.toFixed(
                                            2
                                          )}`}
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>

                              <Grid container className="mb-1">
                                <Grid item xs={4} xl={4} lg={4} md={2}>
                                  <Typography className="text-title text-xs font-black leading-[15px]">
                                    SECA
                                  </Typography>
                                </Grid>
                                <Grid item xs={8} xl={8} lg={8} md={5}>
                                  {isFetchingKpiData &&
                                  selectedRouteId === r.routeId ? (
                                    <Skeleton
                                      animation="wave"
                                      key={routeData}
                                      width="100%"
                                      height={15}
                                    />
                                  ) : (
                                    <Typography className="lowercase text-title text-xs font-medium leading-[15px]">
                                      :{" "}
                                      {r?.routeId === selectRoute?.routeId
                                        ? `${formateCurrency(
                                            routeData?.seca_nm
                                          )} nm` +
                                          " " +
                                          `( ${(routeData?.seca_ptg).toFixed(
                                            1
                                          )} % )`
                                        : `${formateCurrency(
                                            r?.routeDataKPI?.seca_nm
                                          )} nm` +
                                          " " +
                                          `( ${r?.routeDataKPI?.seca_ptg?.toFixed(
                                            1
                                          )} % )`}
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid container className="mb-1">
                                <Grid item xs={4} xl={4} lg={4} md={2}>
                                  <Typography className="text-title text-xs font-black leading-[15px]">
                                    NECA
                                  </Typography>
                                </Grid>
                                <Grid item xs={8} xl={8} lg={8} md={5}>
                                  {isFetchingKpiData &&
                                  selectedRouteId === r.routeId ? (
                                    <Skeleton
                                      animation="wave"
                                      key={routeData}
                                      width="100%"
                                      height={15}
                                    />
                                  ) : (
                                    <Typography className="lowercase text-title text-xs font-medium leading-[15px]">
                                      :{" "}
                                      {r?.routeId === selectRoute?.routeId
                                        ? `${formateCurrency(
                                            routeData?.neca_nm
                                          )} nm` +
                                          " " +
                                          `( ${(routeData?.neca_ptg).toFixed(
                                            1
                                          )} % )`
                                        : `${formateCurrency(
                                            r?.routeDataKPI?.neca_nm
                                          )} nm` +
                                          " " +
                                          `( ${r?.routeDataKPI?.neca_ptg?.toFixed(
                                            1
                                          )} % )`}
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid container className="mb-1">
                                <Grid item xs={4} xl={4} lg={4} md={2}>
                                  <Typography className="text-title text-xs font-black leading-[15px]">
                                    Port Days
                                  </Typography>
                                </Grid>
                                <Grid item xs={8} xl={8} lg={8} md={5}>
                                  {isFetchingKpiData &&
                                  selectedRouteId === r.routeId ? (
                                    <Skeleton
                                      animation="wave"
                                      key={routeData}
                                      width="100%"
                                      height={15}
                                    />
                                  ) : (
                                    <Typography className="uppercase text-title text-xs font-medium leading-[15px]">
                                      :{" "}
                                      {r?.routeId === selectRoute?.routeId
                                        ? `${routeData?.portDaysTotal}`
                                        : `${r?.routeDataKPI?.portDaysTotal}`}
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                className="mb-1 align-items-center"
                              >
                                <Grid item xs={4} xl={4} lg={4} md={2}>
                                  <Typography className="text-title text-xs font-black leading-[15px]">
                                    Total Dist.
                                  </Typography>
                                </Grid>
                                <Grid item xs={8} xl={8} lg={8} md={5}>
                                  {isFetchingKpiData &&
                                  selectedRouteId === r.routeId ? (
                                    <Skeleton
                                      animation="wave"
                                      key={routeData}
                                      width="100%"
                                      height={15}
                                    />
                                  ) : (
                                    <Typography className="lowercase text-title text-xs font-medium leading-[15px]">
                                      :{" "}
                                      {r?.routeId === selectRoute?.routeId
                                        ? `${formateCurrency(
                                            routeData?.nmTotal
                                          )} nm`
                                        : `${formateCurrency(
                                            r?.routeDataKPI?.nmTotal
                                          )} nm`}
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          </>
                        ) : (
                          <div className="routes-loader">
                            <Loading visible={!r?.routeDataKPI} />
                          </div>
                        )}
                      </Box>
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Box>
          <Typography
            variant="h6"
            className="text-updated-primary-text w-fit mt-9 mb-[10px] text-base leading-5 font-black"
          >
            Speed
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box className="bg-updated-primary-text rounded-[7px]">
                <Typography
                  variant="subtitle1"
                  className="lowercase text-white pt-[15px] pb-[15px] text-center items-center font-medium text-xl"
                >
                  {selectSpeed} kt
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box className="flex justify-between">
                <Typography
                  variant="body1"
                  className="lowercase text-title text-base leading-5 font-normal"
                >
                  {speedmin} kt
                </Typography>
                <Typography
                  variant="body1"
                  className="lowercase text-title text-base leading-5 font-medium"
                >
                  {speedmax} kt
                </Typography>
              </Box>
              <Slider
                aria-label="Temperature"
                defaultValue={selectSpeed}
                value={selectSpeed}
                min={vesselData?.speedMin}
                max={vesselData?.speedMax}
                step={0.5}
                sx={CustomSliderStyles}
                onChange={changeSpeed}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default AssignRoute;
