import React, { useState, useRef, useEffect } from "react";
import { Box} from "@mui/material";

import ArrowUp from "../assets/images/arrowUpIcon.svg";
import ArrowDown from "../assets/images/arrowDownIcon.svg";
import { FlexContainer } from "./StylesComponent/FlexContainer";
const SelectBox = ({
        listItems,
        showError,
        selectedItem,
        setSelectedItem,
        setShowError,
        disabledOptions,
        className = "",
        selectBoxContainer = "",
        show = false,
        showSelect = true,
        showErrorPopup = true,
    }) => {
        const [showDropDown, setShowDropDown] = useState(false);
        const selectBoxRef = useRef(null);


        useEffect(() => {
            const handleClickOutside = (event) => {
                if (
                    selectBoxRef.current &&
                    !selectBoxRef.current.contains(event.target)){

                    setShowDropDown(false);
                }
            };

            document.addEventListener("click", handleClickOutside);

            return () => {
            document.removeEventListener("click", handleClickOutside);
            };
        }, []);

        const handleSelect = () => {
            setShowDropDown(!showDropDown);
        };

        const handleItemClick = (item) => {
            if (item.id !== null) {
            setSelectedItem(item);
            setShowDropDown(false);
            if (showErrorPopup) {
                setShowError(false);
            }
            }
        };


        return (
        <Box
            ref={selectBoxRef}
            className={`select-box-container ${selectBoxContainer}`}
            >
                <FlexContainer
                    className={`select-box ${
                    showError ? "error-input" : ""
                    } ${className}`}
                    onClick={handleSelect}
                >   
                    <Box className="selectBox__value">{selectedItem?.item}</Box>
                    <Box className="select-icon">
                        <img src={showDropDown ? ArrowUp : ArrowDown} alt="" />
                    </Box>
                </FlexContainer>
            {showDropDown && (
                <Box className="dropdown-menu-list">
                                <Box className="dropdown-container">
                        {showSelect && (
                            <Box
                                className={`init dropdown-item`}
                                onClick={() =>
                                    handleItemClick({
                                        id: null,
                                        item: `Select`,
                                        })
                                    }
                                >Select
                            </Box>
                        )}
                        {listItems.map((item) => (
                        <Box
                            className={`dropdown-item cursor-pointer ${
                            selectedItem && selectedItem.id === item.id ? "active" : ""
                            } ${disabledOptions?.includes(item.id) ? "disabled" : ""}`}
                            key={item?.id}
                            onClick={() => handleItemClick(item)}
                        >
                            {item.item?item.item:"Unknown Scenario"}
                        </Box>
                        ))}
                    </Box>
                </Box>
            )}
            </Box>
        );
    };

export default SelectBox;
