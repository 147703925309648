import { useEffect } from "react";
import React, { useContext } from "react";
import SessionContext from "../contexts/SessionContext";

export default function useSession() {
  const { user, setUser } = useContext(SessionContext);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUser(user); 
    } else {
      logout();
    }
  }, [localStorage]);

  const login = (user) => {
    localStorage.setItem("user", user);
  };

  const logout = () => {
    localStorage.removeItem("user");
  };

  return { user, login, logout };
}
