import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { Box, Container, Typography, Grid, Button } from "@mui/material";
import { resetPass } from "../../api/inputData";
import { toast, ToastContainer } from "react-toastify";

const schema = yup
  .object({
    email: yup.string().email().required("Please enter valid email"),
  })
  .required();

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors: emailClear,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleReset = (data) => {
    data.stamp = new Date();
    resetPass(data.email)
      .then((res) => {
        if (res.data) {
          toast.success(
            "A reset password link has been sent to your email address!",
            {
              position: "top-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          reset({ email: "" });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        reset({ email: "" });
      });
  };
  return (
    <>
      <Container maxWidth="xl">
        <Grid container className="justify-center">
          <Grid item xs={12} md={12} lg={6} xl={6}>
            <Box className="border border-updated-primary-text bg-list-bg border-solid rounded-[7px] mt-[150px] p-[20px]">
              <Typography
                variant="h1"
                className="text-updated-primary-text font-bold text-center text-5xl leading-[68.32px] mt-[20px]"
              >
                Forgot Your Password ?
              </Typography>
              <Grid className="w-full flex justify-center">
                <Typography
                  variant="span"
                  className="text-updated-sub-text font-normal text-center"
                >
                  To reset your password, enter your email address below
                </Typography>
              </Grid>

              <Box className="mt-[10px] p-[20px]">
                <Grid className="w-full flex justify-center mt-6 mb-1">
                  <input
                    placeholder="Enter Your Email"
                    type="email"
                    name="email"
                    {...register("email")}
                    onChange={(e) => {
                      if (
                        e.target.value === null ||
                        e.target.value.trim() === ""
                      ) {
                        emailClear("email");
                      }
                    }}
                    className="input-mail--forgotpass relative border-updated-primary-text"
                  />
                </Grid>
                {errors.email && (
                  <Grid className="w-full flex justify-center">
                    <Typography className="text-updated-sub-text text-base">
                      {errors.email?.message}
                    </Typography>
                  </Grid>
                )}
                <Grid
                  item
                  className="mt-[35px] mb-[10px] mx-auto flex justify-center"
                >
                  <Button
                    className={
                      "!px-[65px] !py-[15px] module-border-wrap text-base leading-5 text-white font-medium"
                    }
                    type="submit"
                    onClick={handleSubmit(handleReset)}
                  >
                    Reset Password
                  </Button>
                </Grid>
                <Grid item className="w-full flex justify-center">
                  <Link
                    className="text-updated-primary-text font-normal text-base"
                    to="/"
                    target="_blank"
                  >
                    {" "}
                    Back To Login{" "}
                  </Link>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer />
    </>
  );
};

export default ForgotPassword;
