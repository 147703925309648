import React from "react";
import loader from "../assets/images/loader.svg";

const Loading = ({ visible = false }) => {
  if (!visible) return null;
  return (
    <div className="loader-container">
      <div className="wrapper">
        <div className="loader">
          <img src={loader} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Loading;
