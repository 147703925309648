import { createSlice } from "@reduxjs/toolkit";

const SelectedFuelOriginSlice = createSlice({
  name: "selectedFuelOrigin",
  initialState: {
    1: {
      id: 1,
      item: "fossil",
    },
  },
  reducers: {
    setSelectedFuelOrigin(state, action) {
      return action.payload;
    },
  },
});

export const { setSelectedFuelOrigin } = SelectedFuelOriginSlice.actions;

export default SelectedFuelOriginSlice.reducer;
