import { Routes, Route } from "react-router-dom";

import LoginScreen from "../views/new-design/LoginScreen";
import ForgotPassword from "../views/new-design/ForgotPassword";
import ResetPassword from "../views/new-design/ResetPassword";
import FAQs from "../views/new-design/FAQs";
import Terms from "../views/new-design/Terms";

export default function AuthNavigation() {
  return (
    <Routes>
      <Route exact path="/" element={<LoginScreen />}></Route>
      <Route exact path="/forgotpassword" element={<ForgotPassword />}></Route>
      <Route exact path="/faqs" element={<FAQs />}></Route>
      <Route exact path="/terms&condition" element={<Terms />}></Route>
      <Route exact path="/reset-password" element={<ResetPassword />}></Route>

      <Route exact path="*" element={<LoginScreen />}></Route>
    </Routes>
  );
}
