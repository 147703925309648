import * as React from "react";
import "../../index.css";
import { useContext } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import AuthContext from "../../contexts/AuthContext";
import Login from "../../assets/images/login.png";
import { setSelectedFuelOrigin } from "../../redux/reducers/SelectedFuelOrigin";
import { setLegislativeScenarioData } from "../../redux/reducers/selectedLegislativeScenario";
import { setPriceScenarioData } from "../../redux/reducers/selectedPriceScenario";
import { useDispatch } from "react-redux";

const LogoutModal = ({ open, handleClose }) => {
  const { setIsLoggedIn } = useContext(AuthContext);
  const dispatch = useDispatch();

  const handleLogout = () =>{
    localStorage.clear();
    sessionStorage.clear();
    setIsLoggedIn(false);
    handleClose();
    const selectedFuelOriginObject = {
      [1]: {
        id: 1,
        item: "fossil",
      },
    };
    dispatch(setSelectedFuelOrigin(selectedFuelOriginObject));
    dispatch(setLegislativeScenarioData({ id: null, item: "Select" }));
    dispatch(setPriceScenarioData({ id: null, item: "Select", fromYear: null, toYear: null }));

  }
  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="log-outmodal"
        PaperProps={{
          style: {
            backgroundColor: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        disableEscapeKeyDown={true}
      >
        <div className="logout-img">
          <img src={Login} alt="logout" />
        </div>
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "center", fontWeight: "800" }}
        >
          <Typography
            variant="h2"
            className="font-bold"
            style={{ fontWeight: "bold", color: "#8893A4" }}
          >
            Are You Sure Want To Log Out
          </Typography>
        </DialogTitle>

        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            className={
              "module-border-wrap-cancel text-base leading-5 !text-spinel-stone-black1 font-medium"
            }
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={handleLogout}
            autoFocus
            className={
              "module-border-wrap-logout text-base leading-5 !text-spinel-stone-black1 font-medium"
            }
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default LogoutModal;
