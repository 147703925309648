import { BrowserRouter } from "react-router-dom";
import MainNavigator from "./navigations/MainNavigator";
import AuthProvider from "./contexts/AuthProvider";
import SessionProvider from "./contexts/SessionProvider";
import { ToastContainer } from "react-toastify";
import AxiosErrorHandler from "./libs/AxiosErrorHandler";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <AxiosErrorHandler>
            <SessionProvider>
              <ToastContainer />
              <BrowserRouter>
                <MainNavigator />
              </BrowserRouter>
            </SessionProvider>
          </AxiosErrorHandler>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
