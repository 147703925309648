import {
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MenuProps } from "../common/componentStyle";
import PriceChart from "../views/results/PriceChart";
import { TabPanel } from "../common/mUiComponents";

import BioFractionImg from "../assets/images/bio-fraction.svg";
import EFractionImg from "../assets/images/e-fraction.svg";
import SelectBox from "./selectBox";
import {
  setLegislativeScenarioData
} from "../redux/reducers/selectedLegislativeScenario";

import {
  setPriceScenarioData
} from "../redux/reducers/selectedPriceScenario";
import { useDispatch, useSelector } from "react-redux";
import { allLegislativeScenario, allPriceForecastScenario } from "../api/inputData";
import { FlexContainer } from "./StylesComponent/FlexContainer";

const SelectFuel = ({
  currentTab,
  setShowPriceChart,
  showPriceChart,
  selectFuel,
  checkedPrice,
  newDataPriceChart,
  dataPriceChartNormalize,
  fuelType,
  setCheckedPrice,
  setShowFuelMSGLengthgreater,
  setSelectedPort,
  selectedPort,
  fuelTypeName,
  selectFuelFamily,
  selectedFuelArray,
  fuels,
  setSelectedItem1,
  selectedItem1,
  setFuels,
  setSelectFuel,
  setSelectedItem,
  selectedItem,
  setShowMSGError,
}) => {
  const dispatch = useDispatch()
  const [priceForecastScenarios, setPriceForecastScenarios] = useState([]);
  const [legislativeScenarios, setLegislativeScenarios] = useState([]);
  const [showLegislativeError, setShowLegislativeError] = useState(false);
  const [showPriceForecastError, setShowPriceForecastError] = useState(false);

  const legislativeScenario  = useSelector(
    (state) => state.selectedLegislativeScenario.legislativeScenario
  );
  const priceScenario = useSelector(
    (state) => state.selectedPriceScenario.priceScenario
  );

  useEffect(()=>{
    getAllPriceForecastScenario();
    getAllLegislativeScenario();
  },[]);


  const handlePriceScenarioChange = (item) => {
    dispatch(setPriceScenarioData(item));
  };

  const handleLegislativeScenarioChange = (item) => {
    dispatch(setLegislativeScenarioData(item));
  };

  const getAllPriceForecastScenario = () => {
    allPriceForecastScenario()
      .then((res) => {
        if(res.data.length > 0){
          const scenario = res.data.map((s, index) => ({
            id: s?.id,
            item: s?.scenarioName?s?.scenarioName:"Unknown Scenario",
            fromYear:s?.startYear,
            toYear:s?.endYear
          }));

          setPriceForecastScenarios(scenario);
          dispatch(setPriceScenarioData(scenario[0]));
        }else{
          const item = {
            id: null, 
            item: "Select",
            fromYear: null,
            toYear: null
          }

          setPriceForecastScenarios([item]);
          dispatch(setPriceScenarioData(item));
        }
      })
      .catch((err) => {
        console.error("error in price forcast scenario", err);
      });
  };

  const getAllLegislativeScenario = () => {
    allLegislativeScenario()
      .then((res) => {
        if(res.data.length > 0){
          const scenario = res.data.map((s, index) => ({
            id: s?.id,
            item: s?.scenarioName?s?.scenarioName:"Unknown Scenario"
          }));

          setLegislativeScenarios(scenario);
          dispatch(setLegislativeScenarioData(scenario[0]));        
        }else{
          const item = {
            id: null, 
            item: "Select"
          }

          setLegislativeScenarios([item]);
          dispatch(setLegislativeScenarioData(item));        

        }
      })
      .catch((err) => {
        console.error("error in price forcast scenario", err);
      });
  };
  
  return (
    <TabPanel
      value={currentTab}
      index={2}
      className="mt-[40px] mb-[50px] padding-css"
    >
      <Box className="price-forcast-container">
        <Box style={{display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap', textAlign: 'left', gap:'2rem', justifyContent: 'center'}}>
          <Box className="selector-actions w-25">
            <Typography className="selector-names"
            >Pricing Scenario:
            </Typography>
            <SelectBox
              selectedItem={priceScenario}
              listItems={priceForecastScenarios}
              showError={showPriceForecastError}
              setShowError={setShowPriceForecastError}
              setSelectedItem={(item) =>
                handlePriceScenarioChange(item)
              }
              disabledOptions={[priceScenario]}
              showSelect={false}
              className="select-box-v2"
            />
          </Box>
          <Box className="selector-actions w-25 d-flex">
            <Typography className="selector-names">Legislative Scenario:</Typography>
            <SelectBox
              selectedItem={legislativeScenario}
              listItems={legislativeScenarios}
              showError={showLegislativeError}
              setShowError={setShowLegislativeError}
              showSelect={false}
              setSelectedItem={(item) =>
                handleLegislativeScenarioChange(item)
              }
              disabledOptions={[legislativeScenario]}

              className="select-box-v2"
              // selectBoxContainer="selectyear-container"
            />
          </Box>
        </Box>
      </Box>
      <FlexContainer>
        <Grid
          item
          xs={12}
          md={12}
          lg={7}
          className={`flex justify-center ${
            showPriceChart ? "px-10 py-5" : ""
          }`}
        >
          {!showPriceChart ? (
            <Box className="w-[622px] h-[512px] fuel-container">
              <img
                src={"https://storage.googleapis.com/vessel-images/fuel-selection-background.png"}
                alt="vessel"
                className="h-full w-full object-cover rounded-[7px] pb-3"
              />
            </Box>
          ) : (
            <Box className="w-[100%] h-[100%]">
              <PriceChart
                checkedPrice={checkedPrice}
                setCheckedPrice={setCheckedPrice}
                fuelType={fuelType}
                newData={newDataPriceChart}
                dataNormalized={dataPriceChartNormalize}
                fuels={selectFuel}
                fuelTypeName={fuelTypeName}
                selectedPort={selectedPort}
                setSelectedPort={setSelectedPort}
              />
            </Box>
          )}
        </Grid>
        { fuels.length > 0 && (<Grid
          item
          xs={12}
          md={12}
          lg={5}
          className="h-fit-content py-6 pl-6 pr-1 bg-list-bg rounded-2xl"
        >
          <Box className="h-full overflow-auto mr-1">
            {fuels.length > 0 ? fuels?.map((i) => {
              if (i.visibleInFE === true) {
                return (
                  <Card
                    sx={{ backgroundColor: "transparent" }}
                    className={
                      selectFuelFamily.includes(i.fuelFamilyName)
                        ? "selected-bg-gradient relative mr-4 rounded-[7px] my-[10px] cursor-pointer"
                        : "mr-4 rounded-[7px] border border-solid shadow-none border-updated-primary-text my-[10px] cursor-pointer"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      selectedFuelArray(i);
                    }}
                    key={`select-fuel-${i.id}`}
                  >
                    <CardContent className="p-[14px]">
                      <Grid container>
                        <Grid item xs={6}  className="h-[4rem]">
                          <Typography
                            variant="h5"
                            className="mb-1 text-updated-primary-text font-black text-base leading-5 w-fit"
                          >
                            {i?.fuelFamilyName}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className="flex justify-end">
                        {i.bioFractionHigh !== 0 && (
                          <Box
                            className={
                              selectFuelFamily.includes(i.fuelFamilyName)
                                ? "flex flex-col items-center justify-center h-[70px] w-[80px] selected-bg-gradient-box relative mr-[10px]"
                                : "flex flex-col items-center justify-center h-[70px] w-[80px] selected-white-bg-box relative mr-[10px]"
                            }
                            onClick={(e) => {
                              setSelectedItem1(i.id);
                            }}
                          >
                            <Box className="h-[38px] w-[38px] mb-[0px]">
                              <img
                                src={BioFractionImg}
                                alt="bio-fraction"
                                className="w-full h-full object-cover"
                              />
                            </Box>
                            {selectFuelFamily.includes(i.fuelFamilyName) && (
                              <FormControl className="w-full">
                                <Select
                                  value={i.bioFractionVal ?? ""}
                                  displayEmpty
                                  open={selectedItem1 === i.id}
                                  onClose={() => setSelectedItem1(0)}
                                  onOpen={() => setSelectedItem1(i.id)}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const updatedArray = fuels.map((obj) => {
                                      if (
                                        obj.fuelFamilyName ===
                                        i.fuelFamilyName
                                      ) {
                                        return {
                                          ...obj,
                                          bioFractionVal: e.target.value,
                                        };
                                      }
                                      return { ...obj };
                                    });
                                    setFuels(updatedArray);
                                    setSelectFuel(
                                      selectFuel.map((selectFuelItem) => {
                                        if (selectFuelItem.id === i.id) {
                                          return {
                                            ...selectFuelItem,
                                            bioFractionVal: e.target.value,
                                          };
                                        }
                                        return {
                                          ...selectFuelItem,
                                        };
                                      })
                                    );
                                  }}
                                  input={<OutlinedInput />}
                                  MenuProps={MenuProps}
                                  inputProps={{
                                    "aria-label": "Without label",
                                    className: "new-dropdown",
                                  }}
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      color: "#2c8ec6",
                                      top: "1px",
                                      right: "0",
                                    },
                                  }}
                                  defaultValue={0}
                                  renderValue={(selected) => {
                                    return "min " + selected + "%";
                                  }}
                                  className="p-0 text-black text-xs font-medium leading-[15px]"
                                >
                                  {i.bioFraction?.length > 0 &&
                                    i.bioFraction?.map((name, nameIndex) => (
                                      <MenuItem
                                        key={`bioFraction-${nameIndex}`}
                                        value={name}
                                      >
                                        {name}%
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            )}
                          </Box>
                        )}
                        {i.efractionHigh !== 0 && (
                          <Box
                            className={
                              selectFuelFamily.includes(i.fuelFamilyName)
                                ? "flex flex-col items-center justify-center h-[70px] w-[80px] selected-bg-gradient-box relative mr-[3px]"
                                : "flex flex-col items-center justify-center h-[70px] w-[80px] selected-white-bg-box relative mr-[3px]"
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedItem(i.id);
                            }}
                          >
                            <Box className="h-[37px] w-[37px] mb-[0px]">
                              <img
                                src={EFractionImg}
                                alt="e-fraction"
                                className="w-full h-full object-cover"
                              />
                            </Box>
                            {selectFuelFamily.includes(i.fuelFamilyName) && (
                              <FormControl className="w-full">
                                <Select
                                  value={i.eFractionVal ?? ""}
                                  displayEmpty
                                  open={selectedItem === i.id}
                                  onClose={() => setSelectedItem(0)}
                                  onOpen={() => setSelectedItem(i.id)}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const updatedArray = fuels.map((obj) => {
                                      if (
                                        obj.fuelFamilyName ===
                                        i.fuelFamilyName
                                      ) {
                                        return {
                                          ...obj,
                                          eFractionVal: e.target.value,
                                        };
                                      }
                                      return { ...obj };
                                    });
                                    setFuels(updatedArray);
                                    setSelectFuel(
                                      selectFuel.map((selectFuelItem) => {
                                        if (selectFuelItem.id === i.id) {
                                          return {
                                            ...selectFuelItem,
                                            eFractionVal: e.target.value,
                                          };
                                        }
                                        return {
                                          ...selectFuelItem,
                                        };
                                      })
                                    );
                                  }}
                                  input={<OutlinedInput />}
                                  MenuProps={MenuProps}
                                  inputProps={{
                                    "aria-label": "Without label",
                                    className: "new-dropdown",
                                  }}
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      color: "#2c8ec6",
                                      top: "1px",
                                      right: "0",
                                    },
                                  }}
                                  defaultValue={0}
                                  renderValue={(selected) => {
                                    return "min " + selected + "%";
                                  }}
                                  className="p-0 text-black text-xs font-medium leading-[15px]"
                                >
                                  {i.eFraction?.length > 0 &&
                                    i.eFraction?.map((name, nameIndex) => (
                                      <MenuItem
                                        key={`eFraction-${nameIndex}`}
                                        value={name}
                                      >
                                        {name}%
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            )}
                          </Box>
                        )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                );
              }
            }):``}
          </Box>
        </Grid>) }
      </FlexContainer>
    </TabPanel>
  );
};

export default SelectFuel;
