import React from "react";
import { Typography, Container } from "@mui/material";

const terms = [
  {
    id: 1,
    title:
      "This software is currently only available to beta testers by written invitation. Any use by individuals not explicitly invited to our beta testing program is considered unauthorized access.",
  },
  {
    id: 2,
    title:
      "Beta testers agree not to disseminate access credentials to unauthorized users.",
  },
  {
    id: 3,
    title:
      "All intellectual property (including but not limited to designs, images, workflows, data, schemas, and algorithms) contained in this service remain the property of Z-Joule.",
  },
  {
    id: 4,
    title: "We do not currently use tracking cookies.",
  },
  {
    id: 5,
    title:
      "If and when you opt to request an email containing the calculation results, we will record the email address you provide, as well as a timestamp. Otherwise, we do not record the actions of individual users in the system.",
  },
  {
    id: 6,
    title:
      "You may request the removal of your email address from our databases by writing to us directly at",
    email: "contact@z-joule.com",
  },
];

const Terms = () => {
  return (
    <Container
      maxWidth="xl"
      className="flex flex-col items-center relative mt-[80px] pl-[80px] pr-[80px]"
    >
      <Typography
        variant="h1"
        className="text-updated-primary-text font-bold text-center text-6xl leading-[100.32px]"
      >
        Terms & Conditions
      </Typography>

      <ul className="list-items mt-[20px]">
        {terms.map((data) => (
          <li key={data.id}>
            {data.title}
            {data.email && (
              <span>
                <a href={`mailto:${data.email}`}>{data.email}</a>
              </span>
            )}
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default Terms;
