import React from "react";
import { faqAll } from "../../api/inputData";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";

import Loading from "../../component/Loading";
import DropdownArrow from "../../assets/images/select-dropdown.svg";
import UpDropdownArrow from "../../assets/images/up-dropdown.svg";
import ZJoule from "../../assets/images/zjoule.png";

const FAQs = () => {
  const [data, setData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [expandedId, setExpandedId] = useState();
  const navigate = useNavigate();
  const toggleExpand = (id) => {
    setExpandedId(id === expandedId ? null : id);
  };

  const getAllFaq = async () => {
    setIsDataLoading(true);
    await faqAll()
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log("getAllfaq error", error);
        navigate("/");
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  };

  useEffect(() => {
    getAllFaq();
    if (data.length > 0) {
      setExpandedId(data[0].id);
    }
  }, []);

  return (
    <>
      <Container
        maxWidth="xl"
        className="flex min-h-[100vh] flex-col items-center relative mt-[80px] pl-[80px] pr-[80px] pb-[30px]"
      >
        <Typography
          variant="h1"
          className="text-updated-primary-text font-bold text-center text-5xl leading-[100.32px]"
        >
          Frequently Asked Questions (FAQ)
        </Typography>

        {isDataLoading && (
          <Box className="mt-[200px] h-[120px] w-[170px]">
            <Loading visible={true} />
          </Box>
        )}

        {!isDataLoading &&
          data?.map((item) => (
            <>
              <Box
                className="border cursor-pointer border-updated-primary-text rounded-[7px] mt-[10px] p-[20px] lg:w-[650px] w-full"
                key={item.id}
              >
                <Box
                  className="flex justify-between"
                  onClick={() => toggleExpand(item.id)}
                >
                  <Typography
                    className="text-updated-primary-text"
                    dangerouslySetInnerHTML={{ __html: item.fr_question }}
                  ></Typography>
                  <img
                    src={
                      expandedId === item.id ? UpDropdownArrow : DropdownArrow
                    }
                    alt=""
                    className={`${UpDropdownArrow ? "rotate" : ""}`}
                  />
                </Box>
                {expandedId === item.id && (
                  <Typography
                    className="text-base answer-content mt-[10px]"
                    dangerouslySetInnerHTML={{ __html: item.fr_answer }}
                  />
                )}
              </Box>
            </>
          ))}
      </Container>
      <footer className="mt-auto">
      </footer>
    </>
  );
};

export default FAQs;
