import { combineReducers } from "redux";
import SelectedFuelOrigin from "./SelectedFuelOrigin";
import selectedPriceScenario from "./selectedPriceScenario";
import selectedLegislativeScenario from "./selectedLegislativeScenario";

const rootReducer = combineReducers({
  SelectedFuelOrigin,
  selectedPriceScenario,
  selectedLegislativeScenario

});

export default rootReducer;
