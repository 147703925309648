import * as React from "react";
import "../../index.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import CloseImg from "../../assets/images/square-images/close.svg";
import { Chart } from "chart.js";
import { Line } from "react-chartjs-2";
import { formatNumber, formateCurrency } from "../../common/formatter";
import { useEffect } from "react";
import { useState } from "react";
import Loading from "../../component/Loading";

const VesselInfoModal = ({ open, handleClose, data, image }) => {
  const optionsVesselDetails = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        position: "myCustomPositioner",
        displayColors: false,
        callbacks: {
          label: function (context) {
            if (typeof context.formattedValue === "string") {
              let formattedValue = context.formattedValue;

              let label = formattedValue.split(".")[0].replace(/,/g, "");

              let formattedLabel = formatNumber(parseInt(label)).toString();

              return "ME Power : " + formattedLabel + " kW";
            }

            return context.formattedValue;
          },
          title: function (context) {
            if (typeof context[0].label === "string") {
              let speedLabel = context[0].label;
              return "Speed : " + speedLabel + " kt";
            }
          },
        },
      },
      datalabels: {
        display: false,
      },
      legend: {
        position: "bottom",
        boxBorderStyle: "none",
        labels: {
          padding: 30,
          color: "#000",

          font: {
            size: 11,
            lineHeight: 14,
          },
          generateLabels: (chart) => {
            const originalLabels =
              Chart.defaults.plugins.legend.labels.generateLabels(chart);
            originalLabels.forEach((label) => {
              label.fillStyle = "#275DC5";
              label.lineWidth = 0;
            });
            return null;
          },
        },
      },
    },
    tension: 0.1,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "#8893a4", // Customize Y-axis grid color here
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
          callback: function (value, index, ticks) {
            return value.toLocaleString();
          },
        },
        title: {
          display: true,
          text: "ME power [kW]",
          color: "#9E9E9E",
          lineHeight: "10px",
          padding: { bottom: 17 },
        },
      },
      x: {
        grid: {
          color: "#374154", // Customize Y-axis grid color here
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
        },
        title: {
          display: true,
          text: "Speed [kt]",
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
    },
  };
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (data && data?.speedGraph) {
      const speedChartData = {
        labels: Object.keys(data?.speedGraph),
        datasets: [
          {
            label: "Speed",
            data: Object.values(data?.speedGraph),
            fill: false,
            borderColor: "#275DC5",
            tension: 0.1,
          },
        ],
      };
      setValue(speedChartData);
    }
  }, [data]);

  return (
    <>
      {data?.vesselTypeName && (
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="log-outmodal "
          PaperProps={{
            style: {
              backgroundColor: "#FFFFFF",
              boxShadow: "none",
              padding: "1em 1em",
            },
          }}
          disableEscapeKeyDown={true}
          maxWidth="lg"
        >
          <div className="close-btn-placement">
            <Button onClick={handleClose}>
              <img src={CloseImg} alt="close" />
            </Button>
          </div>
          <div className="info-header">
            <div>
              <Box className="img-modal">
                {data?.image ? (
                  <img
                    src={data?.image}
                    alt="vessel"
                    className=" w-full object-fill rounded-[7px] "
                  />
                ) : (
                  <div
                    style={{
                      height: "4em",
                      width: "4em",
                      marginTop: "4em",
                      marginLeft: "2em",
                    }}
                  >
                    <Loading visible={!data?.image} />
                  </div>
                )}
              </Box>
            </div>
            <div>
              <div className="title-header">
                <Grid className="mb-[20px]">
                  <Typography
                    variant="h5"
                    className="ml-0 p-0 normal-case text-sm font-bold"
                  >
                    {data?.vesselTypeName}
                  </Typography>
                </Grid>
              </div>
              <Grid className="flex justify-between gap-2">
                <div className="box-container">
                  <div className="boxes-modal"></div>
                  <Typography variant="p" className="text-modal">
                    Capacity
                  </Typography>
                  <Typography variant="p" className="text-modal-2">
                    {data?.capacity} {data.family?.vesselUnits?.suname}
                  </Typography>
                </div>
                <div className="box-container">
                  <div className="boxes-modal"></div>
                  <Typography variant="p" className="text-modal">
                    ME Power
                  </Typography>
                  <Typography variant="p" className="text-modal-2">
                    {`${formateCurrency(data?.installedMEpower)} kw`}
                  </Typography>
                </div>
                <div className="box-container">
                  <div className="boxes-modal"></div>
                  <Typography variant="p" className="text-modal">
                    AUX power
                  </Typography>
                  <Typography variant="p" className="text-modal-2">
                    {formateCurrency(data?.installedAUXpower)} kw
                  </Typography>
                </div>
                <div className="box-container">
                  <div className="boxes-modal"></div>
                  <Typography variant="p" className="text-modal">
                    Min Speed
                  </Typography>
                  <Typography variant="p" className="text-modal-2">
                    {`${parseFloat(data?.speedMin).toFixed(1)} kt`}
                  </Typography>
                </div>
                <div className="box-container">
                  <div className="boxes-modal"></div>
                  <Typography variant="p" className="text-modal">
                    Max Speed
                  </Typography>
                  <Typography variant="p" className="text-modal-2">
                    {`${parseFloat(data?.speedMax).toFixed(1)} kt`}
                  </Typography>
                </div>
              </Grid>
            </div>
          </div>

          <div className="modal-content">
            <div className="first-content">
              <Box className="properties-table">
                <Box className="flex justify-between">
                  <Box>
                    <Typography variant="subtitle1" className="table-title">
                      Fuel Consumption Curve
                    </Typography>
                  </Box>
                </Box>
                <Divider className="divider" />
                <Box className="chart-detail">
                  <Line data={value} options={optionsVesselDetails} />
                </Box>
              </Box>
              <Box className="properties-table-chart">
                <Box className="box-title">
                  <Box>
                    <Typography variant="subtitle1" className="table-title">
                      Main Particulars
                    </Typography>
                  </Box>
                  <Divider className="divider" />
                </Box>
                <div style={{ padding: "0 1em" }}>
                  <Table aria-label="simple table" className="table-detail">
                    <>
                      <TableBody>
                        {Object.keys(data?.propertiesTable).map((key) => (
                          <TableRow key={key}>
                            <TableCell
                              component=""
                              scope="row"
                              className="table-key"
                            >
                              {key}
                            </TableCell>
                            <TableCell className="table-value">
                              {data?.propertiesTable[key]}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  </Table>
                </div>
              </Box>
            </div>

            <div className="second-content">
              <div className="title">
                <Typography
                  variant="h6"
                  className="ml-0 p-0 normal-case text-sm text-updated-primary-text font-bold"
                >
                  Methodology and Notes
                </Typography>
              </div>
              {data && (
                <div
                  className="text-base mt-[10px] info-text"
                  dangerouslySetInnerHTML={{ __html: data.notesHTML }}
                />
              )}
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default VesselInfoModal;
