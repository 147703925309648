import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  legislativeScenario: { 
    id: null, 
    item: "Select"
  }
};
const SelectedLegislativeSlice = createSlice({
  name: "selectedLegislativeScenario",
  initialState,
  reducers: {
    setLegislativeScenarioData(state, action) {
      state.legislativeScenario = action.payload;
    },
  },
});

export const { setLegislativeScenarioData } = SelectedLegislativeSlice.actions;

export default SelectedLegislativeSlice.reducer;
