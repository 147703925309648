import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import {
  Box,
  Typography,
  Grid,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { MenuProps } from "../../common/componentStyle";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFuelOrigin } from "../../redux/reducers/SelectedFuelOrigin";

const PriceChart = ({
  fuels,
  dataNormalized,
  newData,
  fuelType,
  checkedPrice,
  setCheckedPrice,
  fuelTypeName,
  selectedPort,
  setSelectedPort,
}) => {
  if (newData) {
    var selectPort = Object.keys(newData).map((i) => i);
  }

  const dispatch = useDispatch();

  const SelectedFuelOrigins = useSelector((state) => state.SelectedFuelOrigin);

  const [fuelOrigins, setFuelOrigins] = useState([
    { id: 1, item: "fossil" },
    { id: 2, item: "bio" },
    { id: 3, item: "synth" },
  ]);

  const [label, setLabel] = useState("Price by MMBtu (LHV)");
  useEffect(() => {
    if (checkedPrice === true) {
      setLabel("Price by MMBtu (LHV)");
    } else {
      setLabel("Price by Mt");
    }
  }, [checkedPrice]);

  var chartDataNew = newData[selectedPort];

  for (const key in chartDataNew) {
    chartDataNew[key].sort((a, b) => {
      return a.year - b.year;
    });
  }

  const getTotalYearData = Object.values(chartDataNew)[0];

  if (newData && getTotalYearData) {
    var years = getTotalYearData.map((item) => item.year);
  }

  const newFuelLabels = Object.keys(newData[selectedPort]);

  const names = newFuelLabels.map((id) => fuelTypeName[id]);

  const fuelNames = fuels.map((f) => f.fuelFamilyName);

  const selectedFuelOrigins = Object.values(SelectedFuelOrigins);

  const handleCheckboxChangeOrigin = (event, option) => {
    const checked = event.target.checked;

    const updatedItems = { ...SelectedFuelOrigins };
    delete updatedItems[option.id];

    if (checked) {
      updatedItems[option.id] = {
        id: option.id,
        item: option.item,
      };
    } 

    dispatch(setSelectedFuelOrigin(updatedItems));
  };

  const filteredLabelsNew = names.filter((label) => {
    return selectedFuelOrigins.some((fuelType) =>
      label.includes(fuelType.item)
    );
  });

  let filteredArrayNew = filteredLabelsNew.filter((item) =>
    item
  );

  const fuelDatasetsNew = filteredArrayNew?.map((fuel, index) => {
    const fuelTypeId = fuelTypeName[fuel];
    const getFuelFromArray = fuelType.find((fuel) => fuel.id === fuelTypeId);
    if (chartDataNew)
      return {
        label: fuel,
        data: chartDataNew[fuelTypeName[fuel]].map((item) =>
          item.price_mt.toFixed(2)
        ),
        borderDash:
          getFuelFromArray?.origin?.pattern === "dash"
            ? [6, 6]
            : getFuelFromArray?.origin?.pattern === "dot"
            ? [2, 2]
            : [0, 0],
        backgroundColor: getFuelFromArray?.family?.colorHex,
        borderColor: getFuelFromArray?.family?.colorHex,
        fill: false,
        lineTension: 0.4,
        radius: 1,
        pointRadius: 1.3,
        borderWidth: 2,
      };
  });

  const chartValue = {
    labels: years,
    datasets: fuelDatasetsNew,
  };

  const priceChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        position: "myCustomPositioner",
        callbacks: {
          label: function (context) {
            if (typeof context.formattedValue === "string") {
              let formattedValue = context.formattedValue;

              let newData = parseFloat(formattedValue).toFixed(2);

              // let label = formattedValue.split(".")[0].replace(/,/g, "");

              let formattedLabel = newData.toString();

              return context.dataset.label + " : $" + formattedLabel;
            } else {
              return context.formattedValue;
            }
          },
        },
      },
      legend: {
        position: "bottom",
        boxBorderStyle: "none",
        labels: {
          padding: 20,
          color: "#000",

          font: {
            size: 11,
            lineHeight: 14,
          },
          boxWidth: 70,
          boxHeight: 0,
          generateLabels: (chart) => {
            const originalLabels =
              Chart.defaults.plugins.legend.labels.generateLabels(chart);
            originalLabels.forEach((label) => {
              label.lineWidth = 4;
              label.lineDashOffset = 5;
            });
            return originalLabels;
          },
        },
      },
    },
    tension: 0.1,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
          callback: function (value, index, ticks) {
            return "$" + value.toFixed(1).toLocaleString();
          },
        },
        title: {
          display: true,
          text: "Fuel Price [USD/mt]",
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
        },
      },
    },
  };

  // chart normalized

  if (dataNormalized) {
    var selectPortNormalized = Object.keys(dataNormalized).map((i) => i);
  }

  let [selectedPortNormalized, setSelectedPortNormalized] = useState(
    selectPortNormalized[0]
  );

  var chartDataNormalized = dataNormalized[selectedPortNormalized];

  for (const key in chartDataNormalized) {
    chartDataNormalized[key].sort((a, b) => {
      return a.year - b.year;
    });
  }

  if (dataNormalized && chartDataNormalized[3000]) {
    var yearsNormalized = chartDataNormalized[3000].map((item) => item.year);
  }

  const fuelLabelsNormalized = Object.keys(chartDataNormalized);

  const namesNormalized = fuelLabelsNormalized.map((id) => fuelTypeName[id]);

  const fuelNamesNormalized = fuels.map((f) => f.fuelFamilyName);

  const filteredLabelsNormalized = namesNormalized.filter((label) =>
    selectedFuelOrigins.some((fuelType) => label.includes(fuelType.item))
  );

  let filteredArrayNormalized = filteredLabelsNormalized.filter((item) =>
    item
  );

  const fuelDatasetsNormalized = filteredArrayNormalized?.map((fuel, index) => {
    const fuelTypeId = fuelTypeName[fuel];
    const getFuelFromArray = fuelType.find((fuel) => fuel.id === fuelTypeId);
    if (chartDataNew)
      return {
        label: fuel,
        data: chartDataNormalized[fuelTypeName[fuel]].map((item) =>
          item.price_mt.toFixed(2)
        ),
        borderDash:
          getFuelFromArray?.origin?.pattern === "dash"
            ? [6, 6]
            : getFuelFromArray?.origin?.pattern === "dot"
            ? [2, 2]
            : [0, 0],
        backgroundColor: getFuelFromArray?.family?.colorHex,
        borderColor: getFuelFromArray?.family?.colorHex,
        fill: false,
        lineTension: 0.4,
        radius: 1,
        pointRadius: 1.3,
        borderWidth: 2,
      };
  });

  const chartValueNormalized = {
    labels: yearsNormalized,
    datasets: fuelDatasetsNormalized,
  };

  const priceChartOptionsNormalized = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        position: "myCustomPositioner",
        callbacks: {
          label: function (context) {
            if (typeof context.formattedValue === "string") {
              let formattedValue = context.formattedValue;

              let newData = parseFloat(formattedValue).toFixed(2);

              // let label = formattedValue.split(".")[0].replace(/,/g, "");

              let formattedLabel = newData.toString();

              return context.dataset.label + " : $" + formattedLabel;
            } else {
              return context.formattedValue;
            }
          },
        },
      },
      legend: {
        position: "bottom",
        boxBorderStyle: "none",
        labels: {
          padding: 20,
          color: "#000",
          font: {
            size: 11,
            lineHeight: 14,
          },
          boxWidth: 70,
          boxHeight: 0,
          generateLabels: (chart) => {
            const originalLabels =
              Chart.defaults.plugins.legend.labels.generateLabels(chart);
            originalLabels.forEach((label) => {
              label.lineWidth = 4;
              label.lineDashOffset = 5;
            });
            return originalLabels;
          },
        },
      },
    },
    tension: 0.1,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
          callback: function (value, index, ticks) {
            return "$" + value.toFixed(1).toLocaleString();
          },
        },
        title: {
          display: true,
          text: "Fuel Price [USD/MMBtu]",
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
        },
      },
    },
  };

  return (
    <div>
      <Grid container className="action-container">
        <Grid item xs={12} md={4} lg={4} className="port-select">
          <Typography className="capitalize text-base text-white">
            Select Region
          </Typography>
          <FormControl className="w-full border border-solid border-updated-primary-text rounded-[7px]">
            <Select
              displayEmpty
              defaultValue={selectPort[0]}
              input={<OutlinedInput />}
              MenuProps={MenuProps}
              inputProps={{
                "aria-label": "Without label",
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "#2c8ec6",
                },
              }}
              renderValue={(selected) => {
                return selected;
              }}
              className="text-title text-xs font-medium leading-[15px]"
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedPort(e.target.value);
                setSelectedPortNormalized(e.target.value);
              }}
            >
              {selectPort?.map((port) => (
                <MenuItem key={port} value={port}>
                  {port}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={4} className="port-select">
          <div className="price-by">
            <label className="text-title" style={{ marginBottom: "10px" }}>
              {label}
            </label>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => setCheckedPrice(e.target.checked)}
                  style={{
                    color: `${checkedPrice === true ? "#2c8ec6" : "#ffffff"}`,
                  }}
                  checked={checkedPrice}
                />
              }
              label=""
              style={{ color: "#fff" }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Typography className="capitalize text-base text-title mb-[12px]">
            Select Fuel Type
          </Typography>
          <div className="flex align-items-center">
            {fuelOrigins.map((option, index) => (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    onChange={(e) => handleCheckboxChangeOrigin(e, option)}
                    checked={SelectedFuelOrigins[option.id] !== undefined}
                    style={{ color: "#2c8ec6" }}
                  />
                }
                label={option.item}
                className="text-title"
              />
            ))}
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        className={`px-4 pt-7 pb-[24.57px] mt-[20px] rounded-2xl bg-list-bg`}
      >
        {newData ? (
          <Box className="w-full h-[450px]">
            {checkedPrice === true ? (
              <Line
                data={chartValueNormalized}
                options={priceChartOptionsNormalized}
              />
            ) : (
              <Line data={chartValue} options={priceChartOptions} />
            )}
          </Box>
        ) : (
          "Fetchin Data"
        )}
      </Grid>
    </div>
  );
};

export default PriceChart;
