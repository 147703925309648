import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  Box,
  Typography,
  Container,
  Grid,
  Tabs,
  Tab,
  Button,
  Menu, MenuItem, IconButton
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import { chartContext } from "../../common/ChartContex";

import Arrow from "../../assets/images/nav-arrow-right.svg";
import PDFImg from "../../assets/images/pdfIcon.svg";
import { TabPanel, a11yProps } from "../../common/mUiComponents";
import { formatDate } from "../../common/formatter";
import { tabDesign } from "../../common/componentStyle";
import Logo from "../../assets/images/logo.svg";
import { NavLink } from "react-router-dom";
import ErrorOverlaymodal from "../new-design/ErrorOverlayModal";
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, MenuList, Tooltip } from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import RouteEditorIcon  from "../../assets/images/routeEditor.svg";
import VesselEditorIcon from "../../assets/images/vesselEditor.svg";
import RegulatoryContextIcon from "../../assets/images/regulatoryContextIcon.svg";
import PriceForecastIcon from "../../assets/images/priceEditor.svg";
import vesseltypeEditor from "../../assets/images/vesselTypeEditor.svg";
import  SettingsIcon  from "../../assets/images/settingsIcon.svg";
import LogoutIcon from "../../assets/images/logoutIconNav.svg";


import {
  getAllVesselTypesNew,
  routeAll,
  routeKpi,
  vesselTypesId,
  routePatterns,
  compareCii,
  vesselTypeImages,
  getAllFuelNew,
  sendPdfByEmail,
  getFuelPriceNew,
  getAllFuelTypes,
  allVesrionInfo,
  getUserInfo,
} from "../../api/inputData";

import CompareData from "../new-result/IndexResult";
import Loading from "../../component/Loading";
import Pdf from "../Pdf";
import LogoutModal from "./LogoutModal";
import VesselInfoModal from "../results/VesselInfoModal";
import ZJoule from "../../assets/images/zjoule.png";
import AssignRoute from "../../component/AssignRoute";
import SelectVessel from "../../component/SelectVessel";
import SelectFuel from "../../component/SelectFuel";
import ResultHeader from "../../component/ResultHeader";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFuelOrigin } from "../../redux/reducers/SelectedFuelOrigin";
import { setLegislativeScenarioData } from "../../redux/reducers/selectedLegislativeScenario";
import { setPriceScenarioData } from "../../redux/reducers/selectedPriceScenario";
import { Close } from "@material-ui/icons";
import ListItemText from '@material-ui/core/ListItemText';
import { reactAppConfig } from "../../config/react-app-config";




const vesselsDefaultImage =
  "https://storage.googleapis.com/vessel-images/default_vessel_image.jpg";

const schema = yup
  .object({
    email: yup.string().email().required("Please Enter Valid Mail"),
  })
  .required();

export default function Home() {
  const {
    register,
    handleSubmit,
    formState: { },
    clearErrors: emailClear,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const [showChart, setShowChart] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState(null);

  const [fuelTypeName, setFuelTypeName] = useState({});
  const [ownerInfo, setOwnerInfo] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userPermissions, setPermissions] = useState([]);
  const openHamburger = Boolean(anchorEl);
  const legislativeScenario  = useSelector(
    (state) => state.selectedLegislativeScenario.legislativeScenario
  );
  const priceScenario = useSelector(
    (state) => state.selectedPriceScenario.priceScenario
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseHamburger = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const truncateEmail = (email) => {
    if (!email) return '';
    return email.length > 30 ? `${email.slice(0, 30)}...` : email;
  };

  const fullEmail = ownerInfo?.email || '';

  const handleClickOpen = () => {
    setOpen(true);
    setShowButton(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const componentRef = useRef();
  const componentRef1 = useRef();
  const componentRef2 = useRef();
  const componentRef3 = useRef();
  const componentRef4 = useRef();

  const handleFileShare = (data) => {
    setShowChart(true);
    setIsDownloading(true);
    const chartContainer1 = componentRef1.current;
    const chartContainer2 = componentRef2.current;
    const chartContainer3 = componentRef3.current;
    const chartContainer4 = componentRef4.current;

    setTimeout(async () => {
      const chart1 = await html2canvas(chartContainer1, { scale: 1.25 });
      const chart2 = await html2canvas(chartContainer2, { scale: 1.25 });
      const chart3 = await html2canvas(chartContainer3, { scale: 1.25 });
      const chart4 = await html2canvas(chartContainer4, { scale: 1.25 });

      var pdfWidth = 300;

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a3",
        compress: true,
      });

      const marginLeft = 20;
      const marginTop = 20;
      const addImageToPdf = (chart, isLastChart) => {
        const imgData = chart.toDataURL("image/png");
        let imgWidth = pdfWidth - marginLeft * 2;
        let imgHeight = (chart.height * imgWidth) / chart.width - 20;
        const canvasAspectRatio = chart.width / chart.height;
        const pdfAspectRatio = imgWidth / imgHeight;
        if (canvasAspectRatio < pdfAspectRatio) {
          imgHeight = imgWidth / canvasAspectRatio;
        } else {
          imgWidth = imgHeight * canvasAspectRatio;
        }
        if (chart === chart1) {
          imgHeight = imgWidth / canvasAspectRatio - 40;
        }
        if (chart === chart4) {
          imgHeight = imgWidth / canvasAspectRatio - 100;
        }

        pdf.addImage(
          imgData,
          "PNG",
          marginLeft,
          marginTop,
          imgWidth,
          imgHeight
        );
        if (!isLastChart) {
          pdf.addPage();
        }
      };

      addImageToPdf(chart1);
      addImageToPdf(chart2);
      addImageToPdf(chart3);
      addImageToPdf(chart4, true);

      // pdf.save(`Report#${Math.ceil(Math.random() * 1000)} .pdf`);
      const pdfBlob = pdf.output("blob", {
        filename: `Report-${dataCII.caseId}`,
      });

      sendPdfByEmailData(dataCII.caseId, data.email, pdfBlob, data);

      setShowChart(false);
    }, 4000);
  };

  const sendPdfByEmailData = (caseId, email, pdfFile, data) => {
    const formData = new FormData();
    formData.append("file", pdfFile);

    sendPdfByEmail(caseId, email, formData)
      .then((res) => {
        console.log(res);
        toast.success("Report has been sent", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          toastId: "Report-Send",
        });
      })
      .catch((error) => {
        setError(error);
        setOpenError(true);
        console.log("error", error);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const onSubmit = (data) => {
    data.stamp = new Date();
    if (data) {
      handleFileShare(data);
    } else {
      console.log("Please Try again");
    }
  };

  const [selectFuel, setSelectFuel] = useState([]);
  const [selectFuelFamily, setSelectFuelFamily] = useState([]);

  const [firstTabLabel, setFirstTabLabel] = useState("Select Vessel");
  const [secondTabLabel, setSecondTabLabel] = useState(
    "Select Route And Speed"
  );
  const [thirdTabLabel1, setThirdTabLabel1] = useState("Select Fuels");

  const [routeLabel, setRouteLabel] = useState("Select Route");
  const [speedLabel, setSpeedLabel] = useState("Select Speed");
  const [fuelLabel, setFuelLabel] = useState("Select Fuel");
  const [fuelLabelSub, setFuelLabelSub] = useState(null);
  const [fuelLabelSub1, setFuelLabelSub1] = useState(null);
  const [fuelLabelSub2, setFuelLabelSub2] = useState(null);
  const [fuelLabelSub3, setFuelLabelSub3] = useState(null);
  const [fuelLabel1, setFuelLabel1] = useState(null);
  const [fuelLabel2, setFuelLabel2] = useState(null);
  const [fuelLabel3, setFuelLabel3] = useState(null);
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedItem1, setSelectedItem1] = useState(0);
  const [showPriceChart, setShowPriceChart] = useState(false);
  const [defaultSpeed, setDefaultSpeed] = useState(null);

  const tabs = [
    { id: 1, label: firstTabLabel },
    { id: 2, label: secondTabLabel },
    { id: 3, label: thirdTabLabel1 },
    { id: 4, label: "Compare" },
  ];
  const [currentTab, setCurrentTab] = useState(0);
  const [selectVessel, setSelectVessel] = useState(null);
  const [selectRoute, setSelectRoute] = useState(null);

  const [vesselTypes, setVesselTypes] = useState([]);
  const [vesselTypesImages, setVesselTypesImages] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [isFetchingKpiData, setIsFetchingKpiData] = useState(false);
  const [selectedRouteName, setSelectedRouteName] = useState("");
  const [isLoadingVesselData, setIsLoadingVesselData] = useState(false);
  const [vesselData, setVesselData] = useState(null);
  const [selectedRouteId, setSelectedRouteId] = useState("");
  const [fuels, setFuels] = useState([]);
  const [routeData, setRouteData] = useState();
  const [routePattern, setRoutePattern] = useState(null);
  const [selectSpeed, setSelectSpeed] = useState();
  const [speedProgress, setSpeedProgress] = useState();
  const [speedmin, setSpeedMin] = useState(vesselData?.speedMin);
  const [speedmax, setSpeedMax] = useState(vesselData?.speedMax);
  const [paths, setPaths] = useState([]);
  const [showMSGError, setShowMSGError] = useState("");
  const [isLoadingCompareData, setIsLoadingCompareData] = useState(false);
  const [showFuelMSGLengthgreater, setShowFuelMSGLengthgreater] = useState(false);
  const [showError, setShowError] = useState(false);
  const [changeVesselImg, setChangeVesselImg] = useState(vesselsDefaultImage);

  const [dataCII, setDataCII] = useState(null);
  const [newDataPriceChart, setNewDataPriceChart] = useState(null);
  const [dataPriceChartNormalize, setDataPriceChartNormalize] = useState(null);
  const [imageModal, setImageModal] = useState(null);

  const [fuelPrices, setFuelPrices] = useState({});
  const [fuelType, setFuelType] = useState([]);
  const infoModalref = useRef();

  const [showModal, setShowModal] = useState(false);
  const [versionData, setVersionData] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(true);
  const [scenarioLink, setScenarioLink] = useState(null);
  const [fetchingFuel, setFetchingFuel] = useState(false);


  const getVersionInfo = () => {
    allVesrionInfo()
      .then((res) => {
        setVersionData(res.data);
      })
      .catch((err) => console.log("version data err", err));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showModal &&
        infoModalref.current &&
        !infoModalref.current.contains(event.target)
      ) {
        setShowModal(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showModal, infoModalref]);

  const [checkedPrice, setCheckedPrice] = useState(true);
  let [selectedPort, setSelectedPort] = useState();


  let newData = selectFuel.map((tab) => {
    return fuelType.find((fuel) => {
      return (
        fuel.family.id === tab.id &&
        fuel.origin.originName === "fossil"
      );
    });
  }).filter((data) => data !== undefined);

  let newDataBio = selectFuel.map((tab) => {
    return fuelType.find((fuel) => {
      return (
        fuel.family.id === tab.id &&
        fuel.origin.id === "bio"
      );
    });
  }).filter((data) => data !== undefined);

  let newDataSynth = selectFuel.map((tab) => {
    return fuelType.find((fuel) => {
      return (
        fuel.family.id === tab.id &&
        fuel.origin.originName === "synth"
      );
    });
  }).filter((data) => data !== undefined);

  useEffect(() => {}, [dataCII]);
  useEffect(() => {}, [newDataPriceChart]);


  const canAccessRoute = (requiredPermission) => {
    const today = new Date();
  
    return userPermissions.some((permission) => {
      const permissionDate = new Date(permission.expirationDate);
      // Check if the permission name matches and if it's not expired
      return (
        permission.licensePermission.permissionName === requiredPermission &&
        permissionDate >= today
      );
    });
  };

  let newArr = [];

  
  useEffect(() => {
    newArr.push(...newData, ...newDataBio, ...newDataSynth);

    if (Object.keys(fuelPrices).length === 0) {
      fuelPrices.priceModifiers = {};
    }

    newArr.forEach((item) => {
      const id = String(item.id);
      if (!fuelPrices.priceModifiers[id]) {
        fuelPrices.priceModifiers[id] = 1;
      }
    });
  }, [selectFuel]);

  const CompareDataCII = (data) => {
    setIsLoadingCompareData(true);
    compareCii(data)
      .then((result) => {
        setDataCII(result.data);
        setShowError(false);
      })
      .catch((error) => {
        setError(error);
        setOpenError(true);
        console.log("error", error);
      })
      .finally(() => {
        setIsLoadingCompareData(false);
      });
  };

  useEffect(() => { }, [fuels]);
  
  useEffect(() => {
    getOwnerPermission();
  }, []);

  useEffect(() => {
    if (selectRoute) {
      setSecondTabLabel(`${selectRoute.routeName}`);
      setRouteLabel(selectRoute.routeName);
      setSpeedLabel(` ${selectSpeed} kt`);
    }
  }, [selectSpeed]);

  const changeTab = (event, newValue) => {
    if(fetchingFuel){
      return;
    }
    if (newValue === 2 && selectFuel.length >= 0 && selectRoute !== null  && legislativeScenario.id !== null) {
      setCurrentTab(newValue);
    }
    if (newValue > 2 && selectFuel.length > 0 && selectRoute !== null  && legislativeScenario.id !== null) {
      setCurrentTab(newValue);

      newArr.push(...newData, ...newDataBio, ...newDataSynth);

      if (Object.keys(fuelPrices).length === 0) {
        fuelPrices.priceModifiers = {};
      }

      newArr.forEach((item) => {
        const id = String(item.id);
        if (!fuelPrices.priceModifiers[id]) {
          fuelPrices.priceModifiers[id] = 1;
        }
      });
      if (selectFuel.length >= 1 && legislativeScenario.id !== null && priceScenario.id !== null) {
        CompareDataCII({
          vesselTypeId: vesselData.id,
          routeId: selectRoute.routeId,
          priceScenario: priceScenario.id,
          legislativeScenario: legislativeScenario.id,
          ladenSpeed: selectSpeed,
          imposeEUFuelMaritime: "true",
          from: priceScenario.fromYear+"-01-01",
          to: priceScenario.toYear+"-12-31",
          fuelMixList: selectFuel.map((f) => {
            return {
              fuelFamilyId: f.id,
              bioFraction: f.bioFractionVal / 100,
              syntheticFraction: f.eFractionVal / 100,
            };
          }),
          myPriceModif: { priceModifiers: fuelPrices.priceModifiers },
        });
      }
    }
    if (newValue === currentTab + 1) {
      if (currentTab === 0) {
        if (selectVessel === null) {
          setShowMSGError("Please Select a Vessel Type");
          return;
        }
      } else if (currentTab === 1) {
        if (selectRoute === null) {
          setShowMSGError("Please select Route & Speed");
          return;
        }
      } else if (currentTab === 2) {
        if (currentTab === 2 && fuels.length === 0) {
          setShowMSGError("The selected scenario is not properly formulated or incomplete. Please use the scenario editor to correct the scenario.")
          return;
        }
        else if (selectFuel.length < 1) {
          setShowMSGError("Please Select at least one fuel to Proceed");
          return;
        } else if(legislativeScenario.id == null){
          setShowMSGError("Please Select a Legislative Scenario");
          return;
        } else if (selectFuel.length > 4) {
          setShowFuelMSGLengthgreater(true);
          setTimeout(() => {
            setShowFuelMSGLengthgreater(false);
          }, 2000);
          return;
        }
      }
    } else if (newValue > currentTab + 1) {
      if (currentTab === 0 && selectVessel === null) {
        setShowMSGError("Please Select a Vessel Type");
      } else if (currentTab === 1 && selectRoute === null) {
        setShowMSGError(" Please select Route & Speed");
      }else if (currentTab === 1 && selectRoute !== null && fuels.length === 0) {
        setShowMSGError(" Please select fuel first from next tab");
      }else if (currentTab === 2 && legislativeScenario.id == null && priceScenario.id == null) {
        setShowMSGError("This account does not contain any valid fuel pricing or legislative scenarios. Please use the scenario editors Justo add or edit your scenarios.")
      } else if (currentTab === 2 && legislativeScenario.id == null) {
        setShowMSGError("This account does not contain any valid legislative scenarios. Please create a legislative scenario.")
      }else if (currentTab === 2 && priceScenario.id == null) {
        setShowMSGError("This account does not contain any valid fuel pricing scenarios. Please create a fuel pricing scenario.")
      }else if (currentTab === 2 && fuels.length === 0) {
        setShowMSGError("The selected scenario is not properly formulated or incomplete. Please use the scenario editor to correct the scenario.")
      }
       else if (currentTab === 2 && selectFuel.length < 1) {
        setShowMSGError("Please Select at least one fuel to Proceed");
      }  else if (currentTab === 2 && selectFuel.length > 3 || currentTab === 2 && legislativeScenario.id == null || currentTab === 2 && priceScenario.id == null) {
        setShowFuelMSGLengthgreater(true);
        setTimeout(() => {
          setShowFuelMSGLengthgreater(false);
        }, 2000);
      }else {
        if (currentTab === 0 && selectRoute === null) {
          setShowMSGError("Please Select Route and Speed to Proceed");
        }
      }
      return;
    }
    setShowMSGError("");
    setShowFuelMSGLengthgreater(false);
    setCurrentTab(newValue);
  };

  let newArray = [];

  function selectedFuelArray(i) {
    changeSelectFuel(i);

    if (selectFuel.length < 4 || selectFuelFamily.includes(i.fuelFamilyName)) {
      setShowFuelMSGLengthgreater(false);
      if (selectFuelFamily.includes(i.fuelFamilyName)) {
        newArray = selectFuel.filter(
          (item) => item.fuelFamilyName !== i.fuelFamilyName
        );
        setSelectFuel(newArray);

        setSelectFuelFamily(newArray.map((e) => e.fuelFamilyName));
      } else {
        newArray = [...selectFuel, i];
        setSelectFuel(newArray);
        setSelectFuelFamily(newArray.map((e) => e.fuelFamilyName));
      }
    } else {
      setShowFuelMSGLengthgreater(true);
      setTimeout(() => {
        setShowFuelMSGLengthgreater(false);
      }, 2000);
    }

    // disselected set null

    const updatedArray = fuels.map((obj) => {
      if (selectFuelFamily.includes(obj.fuelFamilyName)) {
        return obj;
      }
      return {
        ...obj,
        bioFractionVal: 0,
        eFractionVal: 0,
      };
    });

    setFuels(updatedArray);
  }

  const changeSelectFuel = (i) => {
    let eFractionLow = i.efractionLow * 100;
    let eFractionHigh = i.efractionHigh * 100;
    let bioFractionLow = i.bioFractionLow * 100;
    let bioFractionHigh = i.bioFractionHigh * 100;

    const eFractionArray = Array.from(
      { length: eFractionHigh - eFractionLow + 1 },
      (_, index) => index + eFractionLow
    );

    const bioFractionArray = Array.from(
      { length: bioFractionHigh - bioFractionLow + 1 },
      (_, index) => index + bioFractionLow
    );

    i.eFraction = eFractionArray;
    i.bioFraction = bioFractionArray;
  };

  const getAllVessel = async () => {
    setIsLoadingVesselData(true);
    await getAllVesselTypesNew()
      .then((response) => {
        if (response.data.length === 0) {
          setError("Vessels not available");
          setOpenError(true);
        } else {
          setVesselTypes(response.data);
        }
      })
      .catch((error) => {
        setError(error);
        setOpenError(true);
        console.log("getAllVessel error", error);
      })
      .finally(() => {
        setIsLoadingVesselData(false);
      });
  };

  // routes
  const getRoutePatterns = async () => {
    await routeAll()
      .then((response) => {
        if (response.data.length === 0) {
          setError("Routes not available");
          setOpenError(true);
        } else {
          setRoutes(response.data);
        }
      })
      .catch((error) => {
        setError(error);
        setOpenError(true);
        console.log("getRoutePatterns error", error);
      });
  };

  //route patterns
  const getRouteMapPatterns = async (routeId) => {
    await routePatterns(routeId)
      .then((response) => {
        setMapLoaded(true);
        setRoutePattern(response.data);
      })
      .catch((error) => {
        setError(error);
        setOpenError(true);
        setMapLoaded(true);
        console.log("getRoutePatterns error", error);
      });
  };

  const changeSpeed = (event) => {
    const {
      target: { value },
    } = event;
    setSelectSpeed(value);
    setSpeedProgress(value);
    if (selectedRouteId && value) {
      baseRouteKpiData(selectedRouteId, value);
    }
  };

  const handleRoute = (r) => {
    setShowMSGError("");
    setSelectRoute(r);
    setSelectedRouteName(r.routeName);
    setSelectedRouteId(r.routeId);
    setSecondTabLabel(`${r.routeName}`);
    setRouteLabel(r.routeName);
    setSpeedLabel(` ${selectSpeed} kt`);
    setSelectFuel([]);
    setSelectFuelFamily([]);
    if (r.routeId && selectSpeed) {
      baseRouteKpiData(r.routeId, selectSpeed);
    }
    if (r.routeId) {
      getRouteMapPatterns(r.routeId);
    }
  };

  const getFuelList = (validPriceScenariosId, patternId) => {
    setFetchingFuel(true)
    setShowMSGError("Fetching Fuel, Please wait...")

    getAllFuelNew(validPriceScenariosId, patternId)
      .then((result) => {
        let data = result.data;
        setShowMSGError("")

        if(data.length === 0){
          setThirdTabLabel1("Select Fuels")
          setShowMSGError("The selected scenario is not properly formulated or incomplete. Please use the scenario editor to correct the scenario.")
        }
        setFuels(
          data.map((i) => ({ ...i, bioFractionVal: 0, eFractionVal: 0 }))
        );
        setFetchingFuel(false)
      })
      .catch((error) => {
        setShowMSGError("")
        setError(error);
        setOpenError(true);
        console.log("error", error);
        setFetchingFuel(false)
      });
  };

  // const getPriceChart = (
  //   validPriceScenariosId,
  //   patternId,
  //   prices = fuelPrices
  // ) => {
  //   getFuelPriceNew(validPriceScenariosId, patternId, false, prices)
  //     .then((res) => {
  //       setNewDataPriceChart(res.data);
  //       var selectPort = Object.keys(res.data).map((i) => i);
  //       setSelectedPort(selectPort[0]);
  //     })
  //     .catch((error) => {
  //       setError(error);
  //       setOpenError(true);
  //       console.log(error);
  //     });
  // };

  // const getPriceChartNormalize = (
  //   validPriceScenariosId,
  //   patternId,
  //   prices = fuelPrices
  // ) => {
  //   getFuelPriceNew(validPriceScenariosId, patternId, true, prices)
  //     .then((res) => {
  //       setDataPriceChartNormalize(res.data);
  //     })
  //     .catch((error) => {
  //       setError(error);
  //       setOpenError(true);
  //       console.log(error);
  //     });
  // };

  const getAllFueslData = () => {
    getAllFuelTypes()
      .then((res) => {
        setFuelType(res.data);
      })
      .catch((error) => {
        setError(error);
        setOpenError(true);
        console.log(error);
      });
  };

  const baseRouteKpiData = (selectedRoute, speed) => {
    setIsFetchingKpiData(true);
    return routeKpi(selectedRoute, speed)
      .then((response) => {
        setRouteData(response.data);
        return { routeId: selectedRoute, data: response.data };
      })
      .catch((error) => {
        setError(error);
        setOpenError(true);
        console.log("error", error);
        return null;
      })
      .finally(() => {
        setIsFetchingKpiData(false);
      });
  };

  useEffect(() => {
    if (routes.length > 0 && defaultSpeed) {
      setIsFetchingKpiData(true);
      Promise.all(routes.map((r) => baseRouteKpiData(r.routeId, defaultSpeed)))
        .then((routeDataArray) => {
          const updatedRoutes = routes.map((route) => {
            const matchingRouteData = routeDataArray.find(
              (data) => data && data.routeId === route.routeId
            );
            if (matchingRouteData) {
              return {
                ...route,
                routeDataKPI: matchingRouteData.data,
              };
            } else {
              return route;
            }
          });
          setRoutes(updatedRoutes);
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setIsFetchingKpiData(false);
        });
    }
  }, [defaultSpeed]);

  const changeSelectedVessel = (i) => {
    setShowMSGError("");
    setSelectVessel(i);

    if (!selectSpeed || selectSpeed > i.speedMax || selectSpeed < i.speedMin) {
      setSelectSpeed(i.speedMin);
      setDefaultSpeed(i.speedMin);
    }

    setChangeVesselImg(
      `https://storage.googleapis.com/vessel-images/vessel-image-${i.id}-large.png`
    );
    vesselMainParticularsData(i.id);
    setFirstTabLabel(i.vesselTypeName);
  };

  const changeVesselTypeImage = (id, type) => {
    vesselTypeImages(id, type)
      .then((response) => {
        const base64String = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        const imageDataUrl = `data:image/png;base64,${base64String}`;

        setChangeVesselImg(imageDataUrl);
      })
      .catch((error) => console.log("error", error));
  };

  const changeVesselTypeImageModal = (id, type) => {
    vesselTypeImages(id, type)
      .then((response) => {
        const base64String = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        const imageDataUrl = `data:image/png;base64,${base64String}`;

        setImageModal(imageDataUrl);
      })
      .catch((error) => console.log("error", error));
  };
  const dataImages = (id, type) => {
    vesselTypeImages(id, type)
      .then((response) => {
        const base64String = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        const imageDataUrl = `data:image/png;base64,${base64String}`;
        setChangeVesselImg(imageDataUrl);
      })
      .catch((error) => console.log("error"));
  };

  const vesselMainParticularsData = (vesselId) => {
    vesselTypesId(vesselId)
      .then((response) => {
        let data = response.data;

        setVesselData(data);

        let speedMin = response.data.speedMin;
        setSpeedMin(`${parseFloat(speedMin).toFixed(1)}`);

        // update speed after selecting vessel

        let speedMax = response.data.speedMax;
        setSpeedMax(`${parseFloat(speedMax).toFixed(1)}`);
      })
      .catch((error) => {
        setError(error);
        setOpenError(true);
        console.log("fetch vessel by id error ", error);
      });
  };

  const customTabLabel = (tab) => {
    let classNames = "";

    if (tab.id === 1 && selectVessel) {
      classNames = "text-white";
    }

    if (tab.id === 2 && selectRoute) {
      classNames = "text-white";
    }
    if (tab.id === 3 && selectFuel?.length >= 1 && legislativeScenario.id !== null && fuels.length > 0) {
      classNames = "text-white";
    }

    return (
      <Box className="flex items-center">
        <Typography
          variant="h1"
          className={`text-3xl leading-[38px] text-number font-black mr-[10px] text-updated-primary-text w-fit ml-[20px] ${classNames}`}
        >{`0${tab.id}`}</Typography>

        <Typography
          variant="h3"
          className={`capitalize text-updated-primary-text w-fit md:text-base text-xs leading-5 hide-text  font-medium text-start mr-[10px] ${classNames}`}
        >
          {tab.label}{" "}
          {tab.id === 2 && selectSpeed && selectRoute && (
            <>
              <Typography
                variant="p"
                className={`lowercase text-updated-primary-text w-fit md:text-base text-xs leading-5  font-medium text-start mr-[10px] content-1 ${classNames}`}
              >
                Speed: {selectSpeed} kt
              </Typography>
            </>
          )}
        </Typography>
      </Box>
    );
  };

  const handleCreateNew = () => {
    setCurrentTab(0);
    setShowError(false);
    setFuelPrices({});
    setVesselData(null);
    setSelectVessel(null);
    setSelectRoute(null);
    setRoutePattern(null);
    setSelectFuel([]);
    setSelectFuelFamily([]);
    setShowPriceChart(false);
    setChangeVesselImg(vesselsDefaultImage);
    setDataCII(null);
    setFirstTabLabel("Select Vessel");
    setSelectedRouteName("");
    setSelectedRouteId("");
    setSecondTabLabel("Select Route And Speed");
    setRouteLabel("Select Route");
    const selectedFuelOriginObject = {
      [1]: {
        id: 1,
        item: "fossil",
      },
    };
    dispatch(setSelectedFuelOrigin(selectedFuelOriginObject));
  };

  // api function
  useEffect(() => {
    getAllVessel();
    getRoutePatterns();
    getAllFueslData();
    if (vesselTypes.id) {
      dataImages();
    }
    getVersionInfo();
  }, []);

  // map

  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [endMarker, setEndMarkers] = useState([]);

  function setMapData() {
    if (!map) return;
    if (routePattern) {
      paths.forEach((e) => {
        e.setMap(null);
      });

      if (markers.length) {
        markers.forEach((e) => e.setMap(null));
      }
      if (endMarker.length) {
        endMarker.forEach((e) => e.setMap(null));
      }

      let arrayShip = [];
      var tempMarkers = [];

      const bounds = new window.google.maps.LatLngBounds();

      routePattern.segments.forEach((segment) => {
        if (segment.geometry === null) {
          arrayShip.push(segment);

          const shipNotMoving = {
            lat: 10.580401,
            lng: -89.110835,
          };

          const infoWindow = new window.google.maps.InfoWindow();

          const markers = new window.google.maps.Marker({
            map: map,
            position: shipNotMoving,
            icon: {
              url: Arrow,
              scaledSize: new window.google.maps.Size(25, 25),
              anchor: new window.google.maps.Point(30, 22),
            },
          });

          markers.addListener("mouseover", () => {
            infoWindow.setContent(
              `<div class="custom-info-window">Vessel is not moving</div>`
            );
            infoWindow.open(map, markers);
          });

          markers.addListener("mouseout", () => {
            infoWindow.close();
          });
        } else if (segment.geometry) {
          let parsedGeometry = JSON.parse(segment.geometry);
          const flightPlanCoordinates = parsedGeometry.map((e) => ({
            lat: e[1],
            lng: e[0],
          }));

          const marker = new window.google.maps.Marker({
            map: map,
            position: {
              lat: parsedGeometry[0][1],
              lng: parsedGeometry[0][0],
            },
          });
          tempMarkers.push(marker);
          setMarkers((state) => [...state, marker]);

          const lastElement = parsedGeometry.pop();
          const markerEnd = new window.google.maps.Marker({
            map: map,
            position: {
              lat: lastElement[1],
              lng: lastElement[0],
            },
          });

          setEndMarkers((state) => [...state, markerEnd]);

          const flightPath = new window.google.maps.Polyline({
            path: flightPlanCoordinates,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 3,
          });

          flightPlanCoordinates.forEach((coordinate) => {
            bounds.extend(
              new window.google.maps.LatLng(coordinate.lat, coordinate.lng)
            );
          });

          flightPath.setMap(map);
          setPaths((state) => [...state, flightPath]);
        }
      });

      map.panToBounds(bounds);
      const center = bounds.getCenter();
      map.setCenter(center);
    }
  }

  useEffect(() => {
    if (mapLoaded) {
      setMapData();
    }
  }, [mapLoaded]);

  useEffect(() => {
    if (!routePattern && !routePattern?.segments) return;
    setMapData();
  }, [routePattern]);

  useEffect(() => {
    if (priceScenario.id !== null && routePattern && currentTab === 2) {
      // getPriceChart(priceScenario.id, routePattern.id);
      // getPriceChartNormalize(
      //   priceScenario.id,
      //   routePattern.id
      // );
      setSelectFuel([]);
      setSelectFuelFamily([]);
      getFuelList(priceScenario.id, routePattern.id);
    }
  }, [priceScenario, routePattern, currentTab]);
  

  useEffect(() => {
    const names = selectFuel.map((item) => item.fuelFamilyName);
    const bio = selectFuel.map((item) => item.bioFractionVal);
    const synthetic = selectFuel.map((item) => item.eFractionVal);
    if (names.length >= 1 && fuels.length > 0 ) {
      setThirdTabLabel1(() => (
        <div className="flex">
            <span className="text-sm flex">
              {names.map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  {index < names.length - 1 && (
                    <div className="dot-aftername"></div>
                  )}{" "}
                </React.Fragment>
              ))}
            </span>
          </div>
      ));

      setFuelLabel(`${names[0]}`);

      setFuelLabelSub(`( min ${bio[0]}% bio; min ${synthetic[0]}% synth )`);

      setFuelLabel1(`${names[1]}`);

      setFuelLabelSub1(`( min ${bio[1]}% bio; min ${synthetic[1]}% synth )`);

      setFuelLabel2(`${names[2]}`);

      setFuelLabelSub2(`( min ${bio[2]}% bio; min ${synthetic[2]}% synth )`);

      setFuelLabel3(`${names[3]}`);

      setFuelLabelSub3(`( min ${bio[3]}% bio; min ${synthetic[3]}% synth )`);
    } else {
      setThirdTabLabel1("Select Fuels");
    }

    if(fuels.length > 0){
      setShowMSGError("");
      setShowFuelMSGLengthgreater(false);
    }
    if (selectFuel?.length > 0  && legislativeScenario.id !== null ) {
      setShowMSGError("");
      setShowFuelMSGLengthgreater(false);
    } else if (selectFuel.length > 0  && legislativeScenario.id !== null) {
      setShowFuelMSGLengthgreater(false);
      setShowMSGError("");
    }
  }, [selectFuel, fuels]);

  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState("Chart Information");
  const [data, setData] = useState(null);

  
  const handleCloseModal = () => {
    setOpenModal(false);
    setTimeout(() => {
      setImageModal(null);
    }, 0);
  };
  const handleVesselInfoModal = (i, img) => {
    const data = {
      ...i,
      image: `https://storage.googleapis.com/vessel-images/vessel-image-${i.id}-thumbnail.png`,
    };
    setData(data);
    setTitle(i.vesselTypeName);

    setTimeout(() => {
      setOpenModal(true);
    }, 10);
  };

  const [position, setPosition] = useState(0);

  const handleLogout = () => {
    setOpen(true);
  }

  const getOwnerPermission = () => {
    getUserInfo()
      .then((result) => {
        setOwnerInfo(result.data);
        setPermissions(result.data.owner.permissions);
      })
      .catch((err) => {
        setError(error);
        setOpenError(true);
        console.error("err get company name", err);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const scrollIcon = document.getElementById("scrollIcon");
    if (scrollIcon && position >= 170) {
      scrollIcon.style.display = "none";
    }
    if(currentTab === 2){
      if(legislativeScenario.id == null && priceScenario.id == null){
        setShowMSGError("This account does not contain any valid fuel pricing scenarios and legislative scenarios. Please create a fuel pricing scenario and legislative scenario.")
        return;
      }else if(priceScenario.id == null){
        setShowMSGError("This account does not contain any valid fuel pricing scenarios. Please create a fuel pricing scenario.")
        return;
      }else if(legislativeScenario.id == null){
        setShowMSGError("This account does not contain any valid legislative scenarios. Please create a legislative scenario.")
        return;
      }
    }
  }, [currentTab, position]);

  function scrollToMainContainer() {
    const mainContainer = document.getElementById("maincontainer");
    mainContainer.scrollIntoView({ behavior: "smooth" });
  }

  window.addEventListener("scroll", function () {
    const scrollIcon = document.getElementById("scrollIcon");
    const scrollPosition = window.scrollY;
    if (scrollIcon) {
      if (scrollPosition > 170) {
        setPosition(scrollPosition);
        scrollIcon.style.display = "none";
      } else {
        scrollIcon.style.display = "block";
      }
    }
  });
  const handleNavigation = (scenario) => {
    const url = reactAppConfig.scenarioManagerUrl+scenario;
    const identifier = localStorage.getItem("identifier")
    const scenarioUri = `${url}/${identifier}`
    setScenarioLink(scenarioUri);
  }
  


  useEffect(() => {
    if (fuelType?.length > 0) {
      const fuelData = fuelType.reduce((acc, item) => {
        acc[item.id] = item.fuelTypeName;
        acc[item.fuelTypeName] = item.id;
        return acc;
      }, {});
      setFuelTypeName(fuelData);
    }
  }, [fuelType]);

  return (
    <>
      <Box>
        <AppBar className="relative py-2 bg-white px-0">
          <Container
            maxWidth="xl"
            className="h-[100%] relative">
                <Box className="flex justify-between items-center h-[100%]">
                  <Box className="flex">
                    <Link to=""><img className="sitelogo" src={Logo} alt="" /></Link>
                  </Box>
                  <Box className="z-joule-container mb-0">
                    <p>Powered by </p>
                    <span>
                      <Link to="https://z-joule.com/" target="_blank">
                        <img src={ZJoule} alt="" />
                      </Link>
                    </span>
                  </Box>
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px'}}>
                    <NavLink to="/faqs" target="_blank">
                      <button className="faq-button text-cyan-blue font-normal" type="button">
                        FAQs
                      </button>
                    </NavLink>
                    <Box>
                      <IconButton
                        id="basic-button"
                        aria-controls={openHamburger ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openHamburger ? 'true' : undefined}
                        onClick={handleClick}
                        style={{
                          color: '#2C8EC6',
                          display: 'flex',
                          gap: '16px',
                          padding: 0,
                        }}
                      >
                        <MenuIcon style={{ fontSize: '44px' }} />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        className="hamburger-dropdown"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right', 
                        }}
                        open={openHamburger}
                        onClose={handleCloseHamburger}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                          disablePadding: true
                        }}
                        sx={{ top: '5px' }}
                      >
                        <Box className="user-info">
                          <Box style={{
                            background: 'var(--z-joule-com-linear-gossamer-curious-blue, linear-gradient(90deg, #06966B 0%, #2C8EC6 100%))',
                            width: '300px',
                            height: '120px',
                            position: 'relative'
                            }}
                            >
                          </Box>
                          <Box style={{
                                position: 'absolute',
                                top: '50%',
                                bottom: '5%',
                                transform: 'translate(-50%, 50%)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '12px',
                              }}>
                            <Avatar alt={ownerInfo?.firstname} src="/static/images/avatar/2.jpg" style={{
                              width: '60px',
                              height: '60px',
                              flexShrink: '0',
                              borderRadius: '40px',
                              border: '2px solid var(--z-joule-com-nero-nero, #FFF)',
                              background: 'var(--sfsf-z-joule-com-lochmara, #0284C4)',
                              position: 'absolute',
                              top: '50 %',
                              }}
                            >
                            </Avatar>
                          </Box>
                        
                        </Box>
                        <Box className="user-names">
                          {ownerInfo?.firstname !== null &&
                            <Box className="">
                              <Typography className="custom-user-info d-flex justify-content-center">{ ownerInfo?.firstname}</Typography>
                            </Box>
                          }
                          <Box className="pb-3 mt-3" style={{paddingBottom:'10px', borderBottom: '1px solid #DFDFDF' }}>
                            <Tooltip title={ fullEmail.length > 30 ? fullEmail : '' } placement="top" arrow>
                                <Box className="d-flex justify-content-center">
                                  <Typography className="custom-user-info">
                                      {fullEmail}
                                  </Typography>
                                </Box>
                            </Tooltip>
                          </Box>
                        </Box>      
                        <MenuList className="d-grid gap-3" sx={{border: '1px solid #DFDFDF', paddingTop: '0px'}}>
                          <MenuItem className="p-[10px]" disabled={!canAccessRoute("EditRoutes")} onClick={() => alert("Edit Routes")}>
                            <ListItemIcon style={{justifyContent:'center'}}>
                              <img src={RouteEditorIcon} alt="" className="premium-icon" />
                            </ListItemIcon>
                            <ListItemText primary="Route Editor" className="editor-menu-list-items" />
                          </MenuItem>
                          <a href={scenarioLink}>
                            <MenuItem className="p-[10px]" disabled={!canAccessRoute("EditVessels")} onClick={() => handleNavigation("vesseleditor")}>
                              <ListItemIcon style={{justifyContent:'center'}}>
                                <img src={VesselEditorIcon} alt="" className="premium-icon" />
                              </ListItemIcon>
                              <ListItemText primary="Vessel Editor" className="editor-menu-list-items" />
                            </MenuItem>
                          </a>
                          <a href={scenarioLink}>
                            <MenuItem className="p-[10px]" disabled={!canAccessRoute("EditVesselTypes")} onClick={() => handleNavigation("vesseltypeeditor")}>
                              <ListItemIcon style={{justifyContent:'center'}}>
                                <img src={vesseltypeEditor} alt="" className="premium-icon" />
                              </ListItemIcon>
                              <ListItemText primary="Vessel Type Editor" className="editor-menu-list-items" />
                            </MenuItem>
                          </a>
                          <a href={scenarioLink}>
                            <MenuItem className="p-[10px]" onClick={() => handleNavigation("fuelpriceforecast")}>
                              <ListItemIcon style={{justifyContent:'center'}}>
                                <img src={PriceForecastIcon} alt="" className="premium-icon" />
                              </ListItemIcon>
                              <ListItemText primary="Fuel Prices Forecast" className="editor-menu-list-items" />
                            </MenuItem>
                          </a>
                          <a href={scenarioLink}>
                            <MenuItem className="p-[10px]" onClick={() => handleNavigation("legislativescenario")} disabled={!canAccessRoute("EditLegislativeScenarios")}>
                              <ListItemIcon style={{justifyContent:'center'}}>
                                  <img src={RegulatoryContextIcon} alt="" className="premium-icon" />
                              </ListItemIcon>
                              <ListItemText primary="Legislative Scenarios" className="editor-menu-list-items" />
                            </MenuItem>
                            </a>
                        </MenuList>
                        <MenuList className="" style={{ borderTop: '1px solid #DFDFDF' }}>
                        <MenuItem disabled={ true } className="p-[10px]" onClick={() => setShowModal((prevShowModal) => !prevShowModal)} ref={infoModalref}>
                            <ListItemIcon style={{justifyContent:'center'}}>
                              <img src={SettingsIcon} alt="" className="premium-icon" />
                            </ListItemIcon>
                            <ListItemText primary="Settings" className="editor-menu-list-items"  />
                          </MenuItem>
                          <MenuItem className="p-[10px]" onClick={handleLogout}>
                            <ListItemIcon style={{justifyContent:'center'}} >
                              <img src={LogoutIcon} alt="" className="premium-icon" />
                            </ListItemIcon>
                            <ListItemText primary="Log Out" className="editor-menu-list-items" />
                          </MenuItem>
                          <LogoutModal open={open} handleClose={handleClose} />
                        </MenuList>
                      </Menu>
                    </Box>
                  </Box>
                </Box>
            </Container>
          </AppBar>

        <Container
          maxWidth="xl"
          className="flex flex-col items-center relative"
        >
          <Grid
            container
            className="xl:flex xl:items-center text-center xl:justify-center"
          >
          </Grid>
          <Grid
            container
            id="maincontainer"
            className="relative w-full items-center flex justify-center border border-updated-sub-text border-solid rounded-2xl data-container"
          >
            <Grid
              item
              xs={11.5}
              className={`${currentTab === 3 ? " " : "h-[150px]"}`}
            >
              {currentTab !== 3 && (
                <Tabs
                  value={currentTab}
                  onChange={changeTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  allowScrollButtonsMobile
                  className="h-[100%]"
                  sx={tabDesign}
                >
                  {tabs.map((tab) => {
                    let className = "";

                    if (tab.id === 1 && !selectVessel) {
                      className = "!opacity-100 focus-class";
                    } else if (tab.id === 1 && selectVessel) {
                      className = "!opacity-100 tab-btn-gradient";
                    }

                    if (tab.id === 2 && currentTab === 1 && !selectRoute) {
                      className = "!opacity-100 focus-class";
                    } else if (tab.id === 2 && selectRoute) {
                      className = "!opacity-100 tab-btn-gradient";
                    }

                    if (
                      tab.id === 3 &&
                      currentTab === 2 &&
                      selectFuel?.length === 0 && 
                      legislativeScenario.id !== null &&
                      fuels.length > 0
                    ) {
                      className = "!opacity-100 focus-class";
                    } else if (fuels.length > 0 && tab.id === 3 && selectFuel?.length >= 1 && legislativeScenario.id !== null 
                    ) {
                      className = "!opacity-100 tab-btn-gradient";
                    }
                    return (
                      <Tab
                        key={tab.id}
                        label={customTabLabel(tab)}
                        {...a11yProps(tab.id)}
                        className={`label-tab ${className}`}
                      />
                    );
                  })}
                </Tabs>
              )}
              {currentTab === 3 && (
                <ResultHeader
                  handleCreateNew={handleCreateNew}
                  showError={showError}
                  dataCII={dataCII}
                  fuelLabel1={fuelLabel1}
                  fuelLabel2={fuelLabel2}
                  fuelLabel3={fuelLabel3}
                  fuelLabel={fuelLabel}
                  speedLabel={speedLabel}
                  fuelLabelSub={fuelLabelSub}
                  fuelLabelSub1={fuelLabelSub1}
                  fuelLabelSub2={fuelLabelSub2}
                  fuelLabelSub3={fuelLabelSub3}
                  routeLabel={routeLabel}
                  firstTabLabel={firstTabLabel}
                />
              )}

              {showMSGError.length > 0 && (
                <Grid className="w-full flex justify-center">
                  <Typography
                    variant="h4"
                    className="text-updated-sub-text text-xl text-center leading-5 mt-[20px]"
                  >
                    {showMSGError}
                  </Typography>
                </Grid>
              )}
              {showFuelMSGLengthgreater && (
                <Grid className="w-full flex justify-center">
                  <Typography
                    variant="h4"
                    className="text-updated-sub-text text-xl text-center leading-5 mt-[15px]"
                  >
                    Maximum Four Fuels are Allowed at this Time.
                  </Typography>
                </Grid>
              )}
            </Grid>
            {
              <Grid item xs={12}>
                <SelectVessel
                  currentTab={currentTab}
                  changeSelectedVessel={changeSelectedVessel}
                  changeVesselImg={changeVesselImg}
                  vesselTypesImages={vesselTypesImages}
                  selectVessel={selectVessel}
                  vesselTypes={vesselTypes}
                  handleVesselInfoModal={handleVesselInfoModal}
                  isLoadingVesselData={isLoadingVesselData}
                />
                <AssignRoute
                  currentTab={currentTab}
                  setMap={setMap}
                  routes={routes}
                  selectRoute={selectRoute}
                  handleRoute={handleRoute}
                  routeData={routeData}
                  selectedRouteId={selectedRouteId}
                  isFetchingKpiData={isFetchingKpiData}
                  changeSpeed={changeSpeed}
                  vesselData={vesselData}
                  speedmin={speedmin}
                  selectSpeed={selectSpeed}
                  speedmax={speedmax}
                  setMapLoaded={setMapLoaded}
                  mapLoaded={mapLoaded}
                />

                <SelectFuel
                  currentTab={currentTab}
                  setShowPriceChart={setShowPriceChart}
                  showPriceChart={showPriceChart}
                  selectFuel={selectFuel}
                  checkedPrice={checkedPrice}
                  newDataPriceChart={newDataPriceChart}
                  dataPriceChartNormalize={dataPriceChartNormalize}
                  fuelType={fuelType}
                  setCheckedPrice={setCheckedPrice}
                  setShowFuelMSGLengthgreater={setShowFuelMSGLengthgreater}
                  setSelectedPort={setSelectedPort}
                  selectedPort={selectedPort}
                  fuelTypeName={fuelTypeName}
                  selectFuelFamily={selectFuelFamily}
                  selectedFuelArray={selectedFuelArray}
                  fuels={fuels}
                  setSelectedItem1={setSelectedItem1}
                  selectedItem1={selectedItem1}
                  setFuels={setFuels}
                  setSelectFuel={setSelectFuel}
                  setSelectedItem={setSelectedItem}
                  selectedItem={selectedItem}
                  setShowMSGError={setShowMSGError}
                />

                <TabPanel value={currentTab} index={3} className="padding-css">
                  <Grid container className="mb-4">
                    <Grid
                      item
                      xs={12}
                      className="h-[870px] py-4 pl-6 pr-1 bg-list-bg rounded-2xl"
                    >
                      <chartContext.Provider value={dataCII}>
                        <CompareData loading={isLoadingCompareData} />
                      </chartContext.Provider>
                    </Grid>
                  </Grid>
                </TabPanel>
                {currentTab !== 3 ? (
                  ""
                ) : (
                  <Grid
                    item
                    className="mt-[40px] mb-[50px] mx-auto email-action-container"
                  >
                    <Grid className="">
                      <input
                        className="input-mail-id"
                        placeholder="Please Enter Your Email"
                        type="email"
                        name="email"
                        defaultValue={localStorage.getItem("userEmail")}
                        {...register("email")}
                        onChange={(e) => {
                          if (
                            e.target.value === null ||
                            e.target.value.trim() === ""
                          ) {
                            emailClear("email");
                          }
                        }}
                      />
                    </Grid>
                    <Button
                      className={
                        "btn-priceforecast !py-[15px]  module-border-wrap disable text-base leading-5 text-white font-medium"
                      }
                      startIcon={
                        <Box component="img" src={PDFImg} alt="pdf" />
                      }
                      onClick={handleSubmit(onSubmit)}
                      disabled={isLoadingCompareData || isDownloading}
                    >
                      {isDownloading ? (
                        "Sending..."
                      ) : (
                        <>
                          Send
                          <span className="!lowercase ms-1">
                            {" "}
                            my results by email
                          </span>
                        </>
                      )}
                    </Button>
                  </Grid>
                )}
              </Grid>
            }
          </Grid>
        </Container>
        <footer>
        </footer>
        <LogoutModal open={open} handleClose={handleClose} />
      </Box>
      {/* print/pdf */}
      <Box
        className="pdf-container"
        sx={{ display: showChart ? "block" : "none" }}
      >
        <chartContext.Provider value={dataCII}>
          <Pdf
            ref={componentRef}
            componentRef1={componentRef1}
            componentRef2={componentRef2}
            componentRef3={componentRef3}
            componentRef4={componentRef4}
          />
        </chartContext.Provider>
      </Box>

      <VesselInfoModal
        data={data}
        open={openModal}
        title={title}
        image={imageModal}
        handleClose={handleCloseModal}
      />

      <ErrorOverlaymodal
        open={openError}
        handleClose={handleClose}
        errorMessage={
          error
            ? error?.response
              ? error?.message === "Network Error"
                ? "The system is temporarily out of service. Please contact us if this error persists."
                : error?.response?.data?.message
                : error
              : "Sorry, something went wrong. Please try again later."
        }
      />
    </>
  );
}

export const InfoModal = ({ showModal, versionData, handleClose }) => {
  const inputString = versionData?.build?.build?.number;
  const firstEightCharacters = inputString?.substring(0, 8);
  return (
    <>
      {showModal && (
        <div className="info-container">
          {!versionData ? (
            <div className="d-flex justify-content-center mt-5">
              <Loading loading={!versionData} height={35} width={35} />
            </div>
          ) : (
            <>
              <div
                className="text-end mb-1 cursor-pointer"
                onClick={handleClose}
              >
                <Close className="text-updated-primary-text" />
              </div>
              <h3>
                Strategic Fuel Selection Framework's all designs, data, and
                algorithms subject to Copyright.
              </h3>
              <ul className="vessel-detail-container bg-transparent">
                <li>
                  <span className="key">Build number BE:</span>
                  <span className="value">
                    {" "}
                    {firstEightCharacters} (
                    {formatDate(versionData?.build?.time)})
                  </span>
                </li>
                <li>
                  <span className="key">Build number FE:</span>
                  <span className="value">
                    {" "}
                    {firstEightCharacters} (
                    {formatDate(versionData?.build?.time)})
                  </span>
                </li>
              </ul>
            </>
          )}
        </div>
      )}
    </>
  );
};
