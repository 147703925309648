import React, { useState, useEffect }  from "react";
import { Backdrop, Box, Modal, Typography, Button } from "@mui/material";
import CloseIcon3 from "../../assets/images/closeIcon3.svg";
import crossIcon from "../../assets/images/crossIcon.svg";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";

const ErrorOverlayModal = ({ open, handleClose, errorMessage, isLoginScreen}) => {

  const [truncatedErrorMessage, setTruncatedErrorMessage] = useState("")

  useEffect(() => {
    if (errorMessage && typeof errorMessage === 'string') {
      if (errorMessage.length > 300) {
        setTruncatedErrorMessage(`${errorMessage.slice(0, 300)}...`);
      } else {
        setTruncatedErrorMessage(errorMessage);
      }
    }
  }, [errorMessage]);

  const [contactUsLink, setContactUsLink] = useState("");
  const { setIsLoggedIn } = useContext(AuthContext);


  const redirectToHome = () => {
    if (errorMessage === 'The system is temporarily out of service. Please contact us if this error persists.') {
      handleLogout();
    }else{
    window.location.href = "/";
    }
  };

  const handleModalClose = (event, reason) => {
    if (reason !== "escapeKeyDown") {
      handleClose(event, reason);
    }
  };

 
 const handleContactUs = () => {
    const subject = 'Error encountered during SF-2 operation';
    const body = `
      The following error was encountered during operations:
      
      ${errorMessage}
    `;
    
    const mailtoLink = `mailto:contact@z-joule.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    setContactUsLink(mailtoLink);
  }
  
  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.clear();
  }

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
    >
      <Backdrop open={open} style={{ zIndex: 1300 }}>
        <Box
          className="error-box"
          sx={{
            bgcolor: "background.paper",
            boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.12)",
            borderRadius: "15px",
            position: "relative",
          }}
        >
          <div
            className="close-popup"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={redirectToHome}
          >
            <img src={CloseIcon3} alt="Close Icon" />
          </div>

          <div className="cross-icon">
            <div className="cross-icon-svg">
              <img src={crossIcon} alt="Cross Icon" />
            </div>

            <div>
              <Typography
                id="error-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  color: "#717171",
                  fontFamily: "Nunito Sans",
                  fontSize: "28px",
                  fontWeight: 600,
                  lineHeight: "1.5",
                  leadingTrim: "both",

                  textEdge: "cap",
                  fontStyle: "normal",
                }}
              >
                ERROR
              </Typography>
            </div>
          </div>
          <Typography
            id="error-modal-description"
            sx={{
              alignSelf: "stretch",
              color: "#000",
              textAlign: "center",
              leadingTrim: "both",

              textEdge: "cap",
              fontFamily: "Nunito Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "19.2px",
              wordWrap:"break-word"
            }}
          >
            {truncatedErrorMessage}
          </Typography>

          <div>
            <Button
              variant="contained"
              style={{
                display: "flex",
                padding: "12px 26px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "23px",
                background: "#2C8EC6",
              }}
              onClick= {handleContactUs}
            >
              <Typography
                component="a"
                href={contactUsLink}
                style={{
                  color: "#FFF",
                  leadingTrim: "both",
                  textEdge: "cap",
                  fontFamily: "Nunito Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "19.2px",
                  textTransform: "uppercase",
                }}
              >
                Contact Us
              </Typography>
            </Button>
          </div>

        {!isLoginScreen && !(errorMessage === 'The system is temporarily out of service. Please contact us if this error persists.') && (
          <Typography
            sx={{
              color: "#717171",
              textAlign: "center",
              fontFamily: "Nunito Sans",
              fontSize: "15px",
              fontWeight: 400,
              mt: 1,
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
            onClick={handleLogout}
          >
            Click here to logout
          </Typography>
        )}
        </Box>
      </Backdrop>
    </Modal>
  );
};

export default ErrorOverlayModal;
