import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../views/new-design/Home";

import Terms from "../views/new-design/Terms";
import FAQs from "../views/new-design/FAQs";
import Pdf from "../views/Pdf";

export default function AppNavigation() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />}></Route>
      <Route exact path="/faqs" element={<FAQs />}></Route>
      <Route exact path="/pdf" element={<Pdf />}></Route>
      <Route exact path="/terms&condition" element={<Terms />}></Route>
    </Routes>
  );
}
