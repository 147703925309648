import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    priceScenario: { 
        id: null, 
        item: "Select",
        fromYear: null,
        toYear: null
    }
};
const SelectedPriceSlice = createSlice({
    name: "selectedPriceScenario",
    initialState,
    reducers: {
        setPriceScenarioData(state, action) {
            state.priceScenario = action.payload;
        },
    },
});

export const { setPriceScenarioData } = SelectedPriceSlice.actions;

export default SelectedPriceSlice.reducer;
