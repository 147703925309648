import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { ButtonStartIcon } from "../common/componentStyle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ResultHeader = ({
  handleCreateNew,
  showError,
  dataCII,
  fuelLabel1,
  fuelLabel,
  fuelLabelSub,
  speedLabel,
  routeLabel,
  fuelLabelSub2,
  fuelLabel2,
  fuelLabel3,
  firstTabLabel,
  fuelLabelSub1,
  fuelLabelSub3,
}) => {
  return (
    <>
      <Grid className="flex justify-start items-start mt-[40px] pl-[50px]">
        <Button
          className="ml-0 p-0 normal-case text-xl leading-[21px] font-medium text-updated-primary-text"
          disableRipple
          sx={ButtonStartIcon}
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            handleCreateNew();
          }}
        >
          Explore New Case
        </Button>
      </Grid>
      {showError && !dataCII && (
        <Grid className="w-full flex justify-center mb-[20px]">
          <Typography
            variant="h4"
            className="capitalize text-white text-xl text-center leading-5 mt-[50px]"
          >
            The calculation could not be completed
          </Typography>
        </Grid>
      )}
      <Grid>
        <Box className="my-[2em] px-[2em]">
          <Grid container spacing={6}>
            <Grid item lg={4} md={4} xs={12}>
              <Box className="bg-list-bg p-5 rounded-2xl route-card">
                <Typography
                  variant="h1"
                  className="capitalize text-new-text text-7xl font-black text-start"
                >
                  01
                </Typography>
                <Typography
                  variant="h6"
                  className="capitalize text-title text-base text-start leading-5 mt-2"
                >
                  Vessel
                </Typography>
                <Typography
                  variant="h6"
                  className="capitalize text-updated-primary-text font-black text-start mt-2 text-base"
                >
                  {firstTabLabel}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box className="bg-list-bg p-5  rounded-2xl route-card">
                <Typography
                  variant="h1"
                  className="capitalize text-new-text text-7xl font-black text-start"
                >
                  02
                </Typography>
                <Typography
                  variant="h6"
                  className="capitalize text-title text-base text-start leading-5 mt-2"
                >
                  Route And Speed
                </Typography>
                <Typography
                  variant="h6"
                  className="capitalize text-updated-primary-text font-black text-start mt-2 text-base"
                >
                  Route: {routeLabel}
                </Typography>
                <Typography
                  variant="h6"
                  className="text-updated-primary-text font-black text-start mt-2 text-base"
                >
                  Speed: {speedLabel}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Box className="bg-list-bg p-5 rounded-2xl route-card">
                <Typography
                  variant="h1"
                  className="capitalize text-new-text text-7xl font-black text-start"
                >
                  03
                </Typography>
                <Typography
                  variant="h6"
                  className="capitalize text-title text-base text-start leading-5 mt-2"
                >
                  Fuels
                </Typography>
                <Typography
                  variant="h6"
                  className="capitalize text-updated-primary-text font-black text-start mt-2 text-base"
                >
                  {fuelLabel}
                  <Typography variant="p" className="!lowercase ms-1 text-sm">
                    {fuelLabelSub}
                  </Typography>
                </Typography>
                {fuelLabel1 && !fuelLabel1.includes("undefined") && (
                  <Typography
                    variant="h6"
                    className="capitalize text-updated-primary-text font-black text-start mt-2 text-base"
                  >
                    {fuelLabel1}
                    <Typography variant="p" className="!lowercase ms-1 text-sm">
                      {fuelLabelSub1}
                    </Typography>
                  </Typography>
                )}
                {fuelLabel2 && !fuelLabel2.includes("undefined") && (
                  <Typography
                    variant="h6"
                    className="capitalize text-updated-primary-text font-black text-start mt-2 text-base"
                  >
                    {fuelLabel2}
                    <Typography variant="p" className="!lowercase ms-1 text-sm">
                      {fuelLabelSub2}
                    </Typography>
                  </Typography>
                )}
                 {fuelLabel3 && !fuelLabel3.includes("undefined") && (
                  <Typography
                    variant="h6"
                    className="capitalize text-updated-primary-text font-black text-start mt-2 text-base"
                  >
                    {fuelLabel3}
                    <Typography variant="p" className="!lowercase ms-1 text-sm">
                      {fuelLabelSub3}
                    </Typography>
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default ResultHeader;
